import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import ticket from "../../../assets/images/ticket.png";

import { useTranslation } from "react-i18next";
import "./Ticket.css";
import { API } from "../../../services/API";
import DrawerComponent from "../../Drawer/DrawerScreen/DrawerComponent";
import { useNavigate } from "react-router-dom";

const Ticket = ({ route }) => {
  const [userData, setUserData] = useState([]);
  const [expandedOrders, setExpandedOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    fetchWalletDetails();
  }, []);

  const fetchWalletDetails = async () => {
    const getTokenDetails = localStorage.getItem("storeData");
    const token = JSON.parse(getTokenDetails);

    const data = JSON.stringify({
      token: token.data.token,
    });

    try {
      const response = await axios.post(API.orderHistory, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const ResponseData = response.data;
      setUserData(ResponseData.Data);
      setExpandedOrders(Array(ResponseData.Data.length).fill(false));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      // Handle error
    }
  };

  const toggleOrderExpansion = (index) => {
    const updatedExpandedOrders = [...expandedOrders];
    updatedExpandedOrders[index] = !updatedExpandedOrders[index];
    setExpandedOrders(updatedExpandedOrders);
  };

  const Item = ({ item, index }) => {
    const isExpanded = expandedOrders[index];

    const convertTo12HourFormat = () => {
      const splitTime = item.drawTime.split(" ")[1];
      const splitHourMinute = splitTime.split(":");
      let hours = parseInt(splitHourMinute[0]);
      const minutes = splitHourMinute[1];
      const meridiem = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minutes} ${meridiem}`;
    };

    const drawTime = convertTo12HourFormat();

    const [endDate, endTime] = item.endTime.split(" ");
    const [day, month, year] = endDate.split("-");
    const formattedEndTime = `${day}-${month}-${year}`;

    return (
      <div className="boxContainer11">
        <div className="row11">
          <div className="anime">
            <img src={ticket} className="lottie1" />

            <div className="textContainer">
              {t("TicketId")}: {item.lotteryId}
            </div>
          </div>
          <div className="textContainer1">
            {t("Date")}: {formattedEndTime}
          </div>
        </div>
        <div className="textContainer4">{t("Ticket Series")}</div>
        <div className="ticketSeriesContainer">
          {isExpanded
            ? item.ticketSeries.map((series, seriesIndex) => (
                <div key={seriesIndex} className="ticketSeriesItem">
                  {series}
                </div>
              ))
            : item.ticketSeries.slice(0, 3).map((series, seriesIndex) => (
                <div key={seriesIndex} className="ticketSeriesItem">
                  {series}
                </div>
              ))}
          {item.ticketSeries.length > 3 && (
            <button
              className="showAllButton"
              onClick={() => toggleOrderExpansion(index)}
            >
              {isExpanded ? "Show Less" : "Show More"}
            </button>
          )}
        </div>
        <div className="row1">
          <div className="textContainer3">
            {t("Draw time")}: {drawTime}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
        <DrawerComponent selecteRoute="Tickets" />
      {/* <div className="DepositHead1">
        <button className="header" onClick={() => navigate(-1)}>
          &larr;
        </button>
        <h1 className="text1">Tickets</h1>
      </div> */}
      {isLoading ? (
        <div className="TicketContainer">
          {/* <Lottie
                        style={{ height: 100, width: 100 }}
                        source={require('./../../assets/loading.json')}
                        autoPlay
                        loop
                    /> */}
        </div>
      ) : (
        <>
          {userData.length === 0 ? (
            <div className="TicketContainer">
              <p>{t("No draws Booked")}</p>
            </div>
          ) : (
            <div className="TicketContainer">

              {/* <div className="list"> */}
              {userData.map((item, index) => (
                <Item key={index} item={item} index={index} />
              ))}
              {/* </div> */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Ticket;
