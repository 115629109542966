import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import i18n from '../../../components/Language/i18next';
// import SkeletonLoader from '../../../components/SkeletonLoader';
import "./UpComingDays.css"; // Assuming you have a CSS file for styles
import { API } from "../../services/API";
import axios from "axios";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";

const UpComingDays = () => {
  const [fetchUser, setFetchUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchLotteryDetails();
  }, []);

  const fetchLotteryDetails = async (selectedPrice) => {
    const token = localStorage.getItem("storeData");
    const parsedToken = JSON.parse(token);

    const data = JSON.stringify({
      token: parsedToken.data.token,
    });

    try {
      const response = await axios.post(API.UpcomingLotteries, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = response.data;
      setFetchUser(responseData.data);
      setLoading(false);

      const selectedLottery = responseData.data.find(
        (lottery) => lottery.dayRefId === selectedPrice
      );
      if (selectedLottery) {
        navigate("/Dayref", { state: { lotteryData: selectedLottery } });
      }
      localStorage.setItem("LotteryInfo", JSON.stringify(responseData));
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const renderItem = (item) => {
    const yourText = item.lotteryDayName.split(" ");
    const firstWord = yourText[0];
    const secondWord = yourText.slice(1).join(" ");

    return (
      <div
        className="UDcover"
        onClick={() => fetchLotteryDetails(item.dayRefId)}
      >
        <div className="UDcover-content">
          <span className="UDprice-container">{firstWord}</span>
          <span className="UDprice-container">{secondWord}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <div className="fit">
          <SkeletonLoader height={100} width={160} count={10} />
        </div>
      ) : (
        <div className="UDcontainer">
          {fetchUser.length === 0 ? (
            <div className="UDslide2">
              <div className="UDslide0">
                <span className="UDtitle-container1">
                  {
                    "Today's tickets are sold out. Boost your chances with tickets for upcoming draws."
                  }
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className="PtitleContainer">{"Upcoming Draws"}</div>
              <div className="UDflatlist">
                {fetchUser.map((item) => (
                  <div key={item.id} className="UDflatlist-item">
                    {renderItem(item)}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UpComingDays;
