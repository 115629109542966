import React, { useEffect, useState } from "react";
import Image from "../assets/images/logooo.png";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { BiSolidMessageDots } from "react-icons/bi";
import { RiDownload2Fill } from "react-icons/ri";
import { FaWallet } from "react-icons/fa6";
import winGo from './../assets/images/color_predition_icon.png';
import dice from './../assets/images/dice_roll_icon.png';
import snake from './../assets/images/snake_ladder_icon.png';
import Lottery from './../assets/images/casino-games-design_24908-60957.png';
import DrawerComponent from "./Drawer/DrawerScreen/DrawerComponent";
import axios from "axios";
import { API } from "../services/API";
import { toast } from "react-toastify";
import Badge from '@mui/material/Badge';
import { IoMdNotifications } from "react-icons/io";

export const Home = () => {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState("Lottery");
  const [wallet, setWallet] = useState();
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    fetchWalletDetails();
    fetchNotifyAvail();
  }, []);

  const fetchWalletDetails = async () => {
    const getTokenDetails = localStorage.getItem("storeData");
    const token = JSON.parse(getTokenDetails);
    const data = JSON.stringify({
      token: token.data.token,
    });

    try {
      const response = await axios.post(API.wallet_info, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;
      setWallet(ResponseData.data.wallet.main_wallet);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchNotifyAvail = async () => {
    const getTokenDetails = localStorage.getItem("storeData");
    const token = JSON.parse(getTokenDetails);
    const data = JSON.stringify({
      token: token.data.token,
    });

    try {
      const response = await axios.post(API.NotifyAvail, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;
      setNotificationCount(ResponseData.data[0].available_notifications);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response) {
      toast.error(error.response.data.message);
    } else if (error.request) {
      console.log("No response received:", error.request);
    } else {
      console.log("Error creating request:", error.message);
    }
  };

  const GameType = [
    { id: 1, source: Lottery, navigate: '/Lottery' },
    { id: 2, source: winGo },
    { id: 3, source: dice },
    { id: 4, source: snake },
  ];

  const handleItemClick = (id) => {
    setSelectedId(id);
    if (id === 1) {
      navigate('/Lottery');
    }
  };

  const renderItem = (item) => {
    return (
      <div className="homeRowStyle" onClick={() => handleItemClick(item.id)}>
        <img
          onClick={() => handleItemClick(item.id)}
          src={item.source}
          className={'homeImage'}
        />
      </div>
    );
  };

  return (
    <div className={"mainContainer"}>
      <div className="HomeHeader">
        <DrawerComponent />
        <div className="HeaderLeft">
          <img src={Image} className="HeaderImage" />
          <span className="HomeTitle">LEGEND7</span>
        </div>

        {/* Notification Icon with Conditional Badge */}
        <div onClick={() => navigate('/Notification')}>
          {notificationCount > 0 ? (
            <Badge badgeContent={notificationCount} color="error" className="badgeStyle">
              <IoMdNotifications style={{ fontSize: 22, marginRight: 15 }} />
            </Badge>
          ) : (
            <IoMdNotifications style={{ fontSize: 22, marginRight: 15 ,color:'#fff'}} />
          )}
        </div>

        <div className="HeaderRight" onClick={() => navigate("/MainLayout/Wallet")} >
          <span className="HeaderWallet">{wallet}</span>
          <FaWallet style={{ fontSize: 20 }} />
        </div>
      </div>

      <div className="HomeStyle2">
        <img
          className="HomeBannerImage"
          src="https://legendrummy.com/assets/banner_1.jpg" />
      </div>

      <div className="homeStyle">
        {GameType.map((item) => renderItem(item))}
      </div>
    </div>
  );
};
