import React, { useState, useEffect } from 'react';
import './Dayref.css'; // Example CSS file for styles
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import Lottie from 'lottie-react';
import ticket from '../../services/JSON/ticket.json';
import { API } from '../../services/API';
import Progressbar from '../ProgressBar/Progressbar';
import { IoArrowBackSharp } from "react-icons/io5";

const SingleDay = ({ route }) => {
    const [lotteryData, setLotteryData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state?.lotteryData) {
            const selectedDayRefId = location.state?.lotteryData.dayRefId;
            // console.log('Selected Day Ref Id:', selectedDayRefId);
            fetchSinglePrice(selectedDayRefId);
        }
        // console.log('inside', location.state)
    }, [location.state]);


    const fetchSinglePrice = async (selectedDayRefId) => {
        try {
            const getStoreData = localStorage.getItem('storeData');
            const token = JSON.parse(getStoreData);
            // console.log("token", token)
            const response = await axios.post(API.dayRefLotteries, {
                token: token.data.token,
                dayRefId: selectedDayRefId
            });
            const responseData = response.data;
            // console.log("123 responseData", responseData)
            const selectedLottery = responseData.data.find(
                (lottery) => lottery.lotteryId === selectedDayRefId
            );
            console.log("selectedLottery", selectedLottery)
            // if (selectedLottery) {
            //     navigate('/PickTickets', { state: { lotteryData: selectedLottery } });
            // } else {
            //     console.log('No lottery found with selected price');
            // }
            setLotteryData(responseData.data);
            setIsLoading(false);
            localStorage.setItem('LotteryInfo', JSON.stringify(responseData));
        } catch (error) {
            console.log('Error:', error.message);
            setIsLoading(false);
        }
    };

    const convertToDate = (dateTimeString) => {
        if (dateTimeString) {
            const date = dateTimeString.split(' ')[0];
            return date;
        }
        return '';
    };

    const convertTo12HourFormat = (timeString) => {
        if (timeString) {
            const splitTime = timeString.split(' ')[1];
            const splitHourMinute = splitTime.split(':');
            let hours = parseInt(splitHourMinute[0]);
            const minutes = splitHourMinute[1];
            const meridiem = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            return `${hours}:${minutes} ${meridiem}`;
        }
        return '';
    };

    const Item = ({ item, onClick }) => {
        const startDate = convertToDate(item.startTime);
        const endDate = convertToDate(item.endTime);
        const drawTime = convertTo12HourFormat(item.drawTime);

        return (
            <div className="item" onClick={onClick}>
                <div className="headContainer">
                    <div className="titleContainer">
                        <span className="title">{item.lotteryDayName}</span>
                        <Lottie animationData={ticket} className='lottie' />
                    </div>
                    <span className="title1">{item.lotteryId}</span>
                </div>
                <hr className="separator" />
                <div className="middle">
                    <span className="price">₹{item.prizeMoney}</span>
                    <button className="DayrefButton" disabled>
                        ₹{item.ticket_cost}
                    </button>
                </div>
                <div className="dateContainer">
                    <span className="date">{endDate}</span>
                </div>
                <div className="progressBarContainer">
                    {console.log(item.percentage)}
                    <Progressbar percent={item.percentage}/>
                </div>
                <div className="bottomContainer">
                    <span className="lottoLeft">Tickets: {item.numberOfTickets}</span>
                    <span className="lottoLeft">Draw time: {drawTime}</span>
                </div>
            </div>
        );
    };

    const onPress = (item) => {
        navigate('/PickTickets', { state: { lotteryData: item } });
    };

    return (
        <>
            {isLoading ? (
                <div className="loadingContainer">
                    <div className="spinner" />
                </div>
            ) : (
                <div className="DayContainer">
                    <div className="DepositHead">
                        <button className="header" onClick={() => navigate(-1)}>
                        <IoArrowBackSharp />
                        </button>
                        <h1 className="text1">{location.state?.lotteryData.lotteryDayName}</h1>
                    </div>
                    {/* <div className="head">
                        <button className="header1" onClick={() => navigate(-1)}>
                        <IoChevronBackCircleOutline className={"backButtonStyle1"} />

                        </button>
                        <span className="text1">{location.state?.lotteryData.lotteryDayName}</span>

                    </div> */}
                    <div style={{marginTop:20}}>
                        {lotteryData.map((item) => (
                            <Item key={item._id} item={item} onClick={() => onPress(item)} />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default SingleDay;
