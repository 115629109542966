import React from "react";
import { useTranslation } from "react-i18next";
import "./Table.css";
import Lottie from "lottie-react";
// import gold from "./../../services/JSON/goldTrophy.json";
// import normal from "./../../services/JSON/normalTrophy.json";
import normal from "../../assets/images/normal.png";
import golden from "../../assets/images/golden.png";

const Table = (props) => {
  console.log("333", props.tableDetail.lotteryWinningDetails);
//   const { table } = props.tableDetail.lotteryWinningDetails;
  const { t, i18n } = useTranslation();

  const renderItem = ({ item }) => (
    <div className="TableRow">
      <div className="TableRank">
        {parseInt(item.rank) <= 3 ? (
          <img src={golden} className="lottie2" />

        ) : (
          // <img
          //     className="trophy"
          //     src={require('./../../services/JSON/goldTrophy.json')} // Adjust path to Lottie file
          //     alt="Gold Trophy"
          // />
          <img src={normal} className="lottie2" />
        )}
        <span>{item.rank}</span>
      </div>
      <div className="TableWinner-count">{item.winnerCount}</div>
      <div className="TablePrize-amount">₹ {item.prizeAmount}</div>
    </div>
  );

  return (
    <div className="fullview">
      {props.tableDetail.lotteryWinningDetails && (
        <div className="TableTable-container">
          <div className="TableHeader">
            <div className="TableHeaderCell">{t("Ranks")}</div>
            <div className="TableHeaderCell">{t("No.of.Winners")}</div>
            <div className="TableHeaderCell">{t("Prize Amount")}</div>
          </div>
          <div className="tableBody">
            {props.tableDetail.lotteryWinningDetails.map((item) => (
              <React.Fragment key={item.key}>
                {renderItem({ item })}
              </React.Fragment>
            ))}
          </div>
          <div className="line"></div>
        </div>
      )}
    </div>
  );
};

export default Table;
