import React from 'react';
import './SkeletonLoader.css';

const SkeletonLoader = ({ width, height, count }) => {
  const loaders = Array.from({ length: count });

  return (
    <div className="skeleton-container">
      {loaders.map((_, index) => (
        <div key={index} className="skeleton" style={{ width: width, height: height }}></div>
      ))}
    </div>
  );
};

export default SkeletonLoader;
