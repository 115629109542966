import { useEffect } from 'react';

function useFocusEffect(callback) {
  useEffect(() => {
    const handleFocus = () => {
      callback();
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [callback]);
}

export default useFocusEffect;
