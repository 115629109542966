import React from 'react'
import ProgressBar from "@ramonak/react-progress-bar";
import './ProgressBar.css'

const Progressbar = ({percent}) =>{
    console.log(percent)
    return (
        <ProgressBar
            completed={percent}
            className="wrapper"
            bgColor='#901d24'
            // barContainerClassName="ProgressContainer"
            // completedClassName="barCompleted"
        />
    )
}

export default Progressbar