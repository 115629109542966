import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import SkeletonLoader from './SkeletonLoader';
import "./MegaPrizes.css";
import { API } from "../../../../services/API";
import SkeletonLoader from "../../../../components/SkeletonLoader/SkeletonLoader";

const MegaPrizes = () => {
  const [fetchUser1, setFetchUser1] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetchMonthlyDetails();
  }, []);

  const fetchMonthlyDetails = async () => {
    const getTokenDetails = localStorage.getItem("storeData");
    const token = JSON.parse(getTokenDetails);

    const data = JSON.stringify({
      token: token.data.token,
    });

    try {
      const response = await axios.post(API.monthlyLotteries, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;
      setFetchUser1(ResponseData.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching monthly lotteries:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    const [datePart] = timestamp.split(" ");
    const [day, month, year] = datePart.split("-");
    return `${day}.${month}.${year}`;
  };

  const convertTo12HourFormat = (time) => {
    const splitTime = time.split(" ")[1];
    const splitHourMinute = splitTime.split(":");
    let hours = parseInt(splitHourMinute[0]);
    const minutes = splitHourMinute[1];
    const meridiem = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${meridiem}`;
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  const onlyCurrency = (currency) => {
    const splitCurrency = currency.split(".")[0];
    return splitCurrency;
  };

  return (
    <>
      {loading ? (
        <div className="Mfit">
          <SkeletonLoader height={210} width={'85%'} count={1} />
        </div>
      ) : (
        // <SkeletonLoader height={120} width={'90%'} />
        <div className="header-container">
          {fetchUser1.length === 0 ? (
            <div className="no-data">
              <p>
                {t(
                  "Today's tickets are sold out. Boost your chances with tickets for upcoming draws."
                )}
              </p>
            </div>
          ) : (
            fetchUser1.length > 0 && (
              <div className="bg">
                <div
                  className="lottery-card"
                  onClick={() =>
                    navigate("/PickTickets", {
                      state: { lotteryData: fetchUser1[0] },
                    })
                  }
                >
                  {/* //    onClick={() => navigate("/SinglePrice", { state: { lotteryData: fetchUser1[0] } })}> */}
                  <div className="lottery-header">
                    <h3>{t("Mega Prizes")}</h3>
                    <p>{fetchUser1[0].lotteryId}</p>
                  </div>
                  <h2>
                    {onlyCurrency(numberFormat(fetchUser1[0].prizeMoney))}
                  </h2>
                  <p>
                    {t("Draw at")} {formatDate(fetchUser1[0].endTime)}{" "}
                    {convertTo12HourFormat(fetchUser1[0].drawTime)}
                  </p>
                  <button className="megaButton">
                    {t("BUY NOW")} ₹ {fetchUser1[0].ticket_cost}
                  </button>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};

export default MegaPrizes;
