import React, { useState } from 'react'
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './KycPage.css'
import { API } from '../../services/API';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const KycPage = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [govt, setGovt] = useState('');
    const [gender, setGender] = useState('');
    const [verifyType, setVerifyType] = useState('');

    const history = useNavigate();


    const options = [
        'Select Option',
        'Passport',
        `Driver's license`,
        'Government-issued ID Card'
    ];
    const defaultOption = options[0];

    const handleSubmit = async () => {

        const getTokenDetails = localStorage.getItem("storeData");
        const token = JSON.parse(getTokenDetails);

        const payload = {
            type: verifyType,
            name: name,
            surname: surname,
            document_no: govt,
            gender: gender,
            token: token.data.token
        };

        try {
            const response = await axios.post(API.kycUpdate, payload, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const ResponseData = response.data;
            console.log(ResponseData)

            if (ResponseData.status) {
                const addData = localStorage.getItem("storeData");
                if (addData) {
                    const storedData = JSON.parse(addData);
                    storedData.data.kycverificationStatus = "S";
                    localStorage.setItem("storeData", JSON.stringify(storedData));
                    history("/MainLayout/Profile");
                }


            }


            const StoringData = JSON.stringify(ResponseData);
            localStorage.setItem("LotteryInfo", StoringData);
        } catch (error) {
            if (error.response) {
                console.log(
                    "Server responded with status code:",
                    error.response.status
                );
                toast.error(error.response.data.message);
                console.log("Response data:", error.response.data.message);
            } else if (error.request) {
                console.log("No response received:", error.request);
            } else {
                console.log("Error creating request:", error.message);
            }
        }

    };


    return (
        <div className='KycContainer'>
            <div className="DepositHead">
                <button className="header" onClick={() => navigate(-1)}>
                    <IoArrowBackSharp />
                </button>
                <h1 className="text1">KYC Verification</h1>
            </div>

            <div className='KycVerify'>
                <label className='KycLabel1'>Verify Account</label>

                <label className='KycLabel'>Verify type<span className='required'>*</span></label>
                <Dropdown
                    onFocus={true}
                    options={options}
                    value={verifyType}
                    onChange={(option) => setVerifyType(option.value)}
                    placeholder="Select an option"
                    className='KycDropdown'
                />

                <label className='KycLabel'>Given Name<span className='required'>*</span></label>
                <input
                    id='search'
                    type='text'
                    className='KycInput'
                    placeholder=''
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <label className='KycLabel'>Surname<span className='required'>*</span></label>
                <input
                    id='search'
                    type='text'
                    className='KycInput'
                    placeholder=''
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                />

                <label className='KycLabel'>Passport/NID/License Number<span className='required'>*</span></label>
                <input
                    id='search'
                    type='text'
                    className='KycInput'
                    placeholder='Passport/NID/License Number'
                    value={govt}
                    onChange={(e) => setGovt(e.target.value)}
                />

                <label className='KycLabel'>Gender<span className='required'>*</span></label>
                <div className='KycGender'>
                    <label>
                        <input
                            type='radio'
                            value='Male'
                            checked={gender === 'Male'}
                            onChange={(e) => setGender(e.target.value)}
                        />
                        Male
                    </label>
                    <label>
                        <input
                            type='radio'
                            value='Female'
                            checked={gender === 'Female'}
                            onChange={(e) => setGender(e.target.value)}
                        />
                        Female
                    </label>
                </div>
                <button className='KycButton' onClick={handleSubmit}>
                    Submit
                </button>
                <ToastContainer/>
            </div>
        </div>
    )
}

export default KycPage;
