import React from 'react'
import { Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Import the Menu icon
import MainLayout from '../../AllTab/MainLayout/MainLayout';
import Home from '../../AllTab/Home/Lottery';
import Layout from '../DrawerScreen/Layout';


function HomeDrawer() {
  return (
    <div>
      <Home/>
      {/* <MainLayout /> */}
      {/* <Home/> */}
    </div>
    // <div>
    //   HOME

    //   <br />
    //   <br />
    //   <br />
    //   Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ut error voluptates eligendi quis itaque dolorum officiis, aliquam quia exercitationem labore velit consequatur expedita, beatae corrupti suscipit veniam laborum dolore perferendis?
    // </div>
  )
}

export default HomeDrawer