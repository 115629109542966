import React, { useState } from 'react';
import './Privacy.css'

function Privacy() {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div  className="htmlContainer">
            {isLoading ? (
                <div className="loadingScreen">
                    <div className="loader"></div>
                </div>
            ) : (
                <iframe
                    title="Privacy Policy"
                    src="https://legend7.in/policy/privacypolicy.html"
                    className="webview"
                />
            )}
        </div>
  )
}

export default Privacy
