
// DeleteMyData.js

import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import axios from 'axios';
import './DeleteInfo.css';
import { API } from '../../../services/API';

const DeleteInfo = () => {
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePress = async () => {
    const status = localStorage.getItem('storeData');
    const token = JSON.parse(status);
    
    try {
      const response = await axios.post(API.userDataDeleteRequest, { token: token.data.token });
      const responseData = response.data;
      alert(responseData.message);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Dcontainer">
      <div className="checkboxContainer">
        <Checkbox
          checked={checked}
          onChange={() => setChecked(!checked)}
          color="primary"
        />
        <span className="checkboxLabel">Are you sure you want to delete?</span>
      </div>
      <Button
        variant="contained"
        className="DeleteButton"
        disabled={!checked || isLoading}
        onClick={handlePress}
      >
        Delete Data
      </Button>
    </div>
  );
};

export default DeleteInfo;
