import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CustomDrawer from "./CustomDrawer";
import "./DrawerComponent.css";

const DrawerComponent = ({ selecteRoute }) => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const routes = [
    { path: "/MainLayout/Home", name: "Home" },
    { path: "/Layout/Privacy", name: "Privacy Policy" },
    { path: "/Notification", name: "Notification" },

    { path: "/Layout/TC", name: "Terms Conditions" },
    { path: "/Layout/DeleteInfo", name: "Delete Info" },
    { path: "/Layout/SignOut", name: "SignOut" },
  ];

  useEffect(() => {
    const currentRoute = routes.find(
      (route) => route.path === location.pathname
    );
    if (currentRoute) {
      setSelectedRoute(currentRoute.name);
    }
  }, [location.pathname, routes]);

  return (
    <>
      <div className="DepositHead1">
        <button className="header" onClick={toggleDrawer}>
          <MenuIcon />
        </button>
        <h1 className="text1">
          {selectedRoute === "Privacy Policy"
            ? "Privacy Policy"
            : selectedRoute === "Terms Conditions"
              ? "Terms Conditions"
              : selectedRoute === "Delete Info"
                ? "Delete Info"
                : selectedRoute === "SignOut"
                  ? "SignOut"
                   : selectedRoute === "Notification"
                  ? "Notification"
                  : selecteRoute === "Tickets"
                    ? "Tickets"
                    : selecteRoute === "Wallet"
                      ? "Wallet"
                      : selecteRoute === "Result"
                        ? "Result"
                        : selecteRoute === "Profile"
                          ? "Profile"
                          : ""}
        </h1>
        <CustomDrawer
          isOpen={drawerOpen}
          onClose={toggleDrawer}
          routes={routes}
        />
      </div>
    </>
  );
};

export default DrawerComponent;
