import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API } from "../../../services/API";
import "./Result.css";
import DrawerComponent from "../../Drawer/DrawerScreen/DrawerComponent";

const Result = () => {
  const [fetchWinnersDetails, setFetchWinnersDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchResultDetails();
  }, []);

  const fetchResultDetails = async () => {
    const getTokenDetails = localStorage.getItem("storeData");

    const token = JSON.parse(getTokenDetails);
    console.log("Details ##", token.data.token);
    try {
      const response = await axios.post(API.previousLotteriesDetails, {
        token: token.data.token,
      });
      const responseData = response.data;
      setFetchWinnersDetails(responseData.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching results:", error);
      setLoading(false);
    }
  };

  const onPress = (item) => {
    navigate("/WinnerList", { state: { lotteryId: item.lotteryId } });
  };

  const renderItem = (item) => {
    const convertOnlyDateFormat = (time) => {
      const splitTime = time.split("T");
      const datePart = splitTime[0];
      return datePart;
    };

    const endTime = convertOnlyDateFormat(item.endTime);

    return (
      <div
        key={item.lotteryId}
        className="boxContainer"
        onClick={() => onPress(item)}
      >
        <div className="ResultRow">
          <span className="text-container0">
            {t("TicketId")}: {item.lotteryId}
          </span>
          <span className="Result-text-container1">{endTime}</span>
        </div>
        <div className="Result-text-container">
          <span className="PrizeMoney">₹ {item.prizeMoney}</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <DrawerComponent selecteRoute="Result" />
      {loading ? (
        <div>Loading...</div>
      ) : fetchWinnersDetails.length === 0 ? (
        <div>{t("No Result found")}</div>
      ) : (
        <div className="ResultContainer">
          <div className="HeaderCell">Ticket IDs</div>
          {fetchWinnersDetails.map(renderItem)}
        </div>
      )}
    </div>
  );
};

export default Result;
