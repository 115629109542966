import React, { useState, useEffect } from "react";
import animationData from "./../../services/JSON/verify.json"; // Ensure this path is correct
import "./SplashProfileUpdation.css";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";

const Splash = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
      navigate("/MainLayout/Ticket");
    }, 1000);

    return () => clearTimeout(delay);
  }, [navigate]);

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  if (isLoading) {
    return (
      <div className="SplashContainer">
        <Lottie
          animationData={animationData}
          className="SplashLottie"
          //  height={200} width={200} style={{backgroundColor:'#000'}}
        />
        {/* <div className="SplashTextContainer"> */}
          <h1 className="SplashBold">Thank You</h1>
          <p className="SplashLight">Booked Your Tickets</p>
        {/* </div> */}
      </div>
    );
  }

  return null;
};

export default Splash;
