import React, { useState, useEffect } from "react";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import "./PicketTickets.css";
import axios from "axios";
import { API } from "../../services/API";
import Table from "./../../components/Table/Table";
import { IoArrowBackSharp } from "react-icons/io5";

const PickTickets = () => {
  const [views, setViews] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [fetchUser, setFetchUser] = useState("");
  const [fetchReadUser, setFetchReadUser] = useState("");
  const [main_wallet, setMain_wallet] = useState();
  const [winning_Wallet, setWinning_Wallet] = useState();
  const [showAddOneButton, setShowAddOneButton] = useState(true);
  const [showCustomizeScreen, setShowCustomizeScreen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.lotteryData) {
      const selectedlotteryId = location.state?.lotteryData.lotteryId;
      FetchSinglePrice(selectedlotteryId);
      FetchReadUser();
    }
  }, [location.state, views]);

  const FetchSinglePrice = async (selectedlotteryId) => {
    var getSinglePrice = localStorage.getItem("storeData");
    const token = JSON.parse(getSinglePrice);

    const data = JSON.stringify({
      token: token.data.token,
      lotteryId: selectedlotteryId,
    });

    try {
      const response = await axios.post(API.singleLottery, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;
      setFetchUser(ResponseData.data[0]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const FetchReadUser = async () => {
    var getSinglePrice = localStorage.getItem("storeData");
    const token = JSON.parse(getSinglePrice);
    const data = JSON.stringify({
      token: token.data.token,
    });

    try {
      const response = await axios.post(API.readSingleuser, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;

      setFetchReadUser(ResponseData.data[0]);
      setMain_wallet(ResponseData.data[0].wallet.main_wallet);
      setWinning_Wallet(ResponseData.data[0].wallet.win_wallet);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const QuickPick = async (count) => {
    try {
      const tokenData = localStorage.getItem("storeData");
      const token = JSON.parse(tokenData);
      const data = JSON.stringify({
        lotteryId: location.state?.lotteryData.lotteryId,
        count,
        token: token.data.token,
      });

      const response = await axios.post(API.picktickets, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const ResponseData = response.data;
      const newItems = ResponseData.data;

      setViews(newItems);
      setCount(newItems.length);
      setShowAddOneButton(true);
      setShowCustomizeScreen(false);
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    }
  };

  const addView = async () => {
    try {
      const tokenData = localStorage.getItem("storeData");
      const token = JSON.parse(tokenData);
      const data = JSON.stringify({
        lotteryId: location.state?.lotteryData.lotteryId,
        count: 1,
        token: token.data.token,
      });

      const response = await axios.post(API.picktickets, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const responseData = response.data;
      const newItems = responseData.data[0];
      setViews([...views, newItems]);
      setCount((prev) => prev + 1);
      setShowAddOneButton(true);
      setShowCustomizeScreen(false);
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    }
  };

  const removeView = (index) => {
    setViews(views.filter((view, i) => i !== index));
    setCount((prev) => prev - 1);
  };

  const convertTo12HourFormat = () => {
    if (fetchUser && fetchUser.drawTime) {
      const splitTime = fetchUser.drawTime.split(" ")[1];
      const splitHourMinute = splitTime.split(":");
      let hours = parseInt(splitHourMinute[0]);
      const minutes = splitHourMinute[1];
      const meridiem = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minutes} ${meridiem}`;
    }
    return "";
  };

  const convertDate = () => {
    if (fetchUser && fetchUser.drawTime) {
      const splitTime1 = fetchUser.drawTime.split(" ")[0];
      return splitTime1;
    }
    return "";
  };

  const drawTime = convertTo12HourFormat();
  const drawDate = convertDate();

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  const onlyCurrencyof = numberFormat(fetchUser.prizeMoney);

  const onlyCurrency = (currency) => {
    const splitCurrency = currency.split(".")[0];
    return splitCurrency;
  };

  const HandlePressPurchase = async () => {
    try {
      const tokenData = localStorage.getItem("storeData");
      const tokenPurchase = JSON.parse(tokenData);
      const totalAmount = count * fetchUser.ticket_cost;

      if (main_wallet >= totalAmount || winning_Wallet >= totalAmount) {
        const lotteryPurchasePayload = JSON.stringify({
          token: tokenPurchase.data.token,
          lotteryId: location.state?.lotteryData.lotteryId,
          tickets: views,
          amount: totalAmount,
        });

        const response = await axios.post(
          API.completeOrder,
          lotteryPurchasePayload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const ResponseData = response.data;
        // console.log(ResponseData);
        if (ResponseData.success) {
          navigate("/Splash");
        }
      } else {
        const AmountToAdd = totalAmount - main_wallet;
        navigate("/Deposit", { state: { AmountToAdd: AmountToAdd } });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      {fetchUser && (
        <div className="PickTicketsContainer">
          <div className="DepositHead">
            <button className="header" onClick={() => navigate(-1)}>
              <IoArrowBackSharp />
            </button>
            <h1 className="text1">
              {location.state?.lotteryData.lotteryDayName} -{" "}
              {location.state?.lotteryData.lotteryId}
            </h1>
          </div>

          <div className="Sprice">
            <p className="StimeContainer1">
              {location.state?.lotteryData.lotteryDayName}
            </p>
            <div className="SrowContainer">
              <p className="SpriceContainer">{onlyCurrency(onlyCurrencyof)}</p>
              <p className="SidContainer">{fetchUser.lotteryId}</p>
            </div>
            <p className="StimeContainer">{drawDate}</p>
            <p className="StimeContainer">
              {"Draw time"}: {drawTime}
            </p>
          </div>

          {fetchUser.is_disabled !== true && (
            // <>
            <div className="Scontainer">
              <div className="SrowContainer1">
                <button
                  onClick={() => QuickPick(5)}
                  className="SquickContainer"
                >
                  <span className="SquickTextContainer">{"Quick Pick"} 5</span>
                </button>
                <button
                  onClick={() => QuickPick(10)}
                  className="SquickContainer"
                >
                  <span className="SquickTextContainer">{"Quick Pick"} 10</span>
                </button>
                <button
                  onClick={() => QuickPick(25)}
                  className="SquickContainer"
                >
                  <span className="SquickTextContainer">{"Quick Pick"} 25</span>
                </button>
              </div>
              <div className="scroll">
                <div className="ScartBorder">
                  <div>
                    <div className="ScartContainer">
                      <span className="ScartTextContainer">
                        {"Cart"} ({count})
                      </span>
                    </div>
                    <div className="Slist">
                      {views.map((item, index) => (
                        <div key={index} className="removeView">
                          <span className="Tbutton5">{item}</span>
                          <button
                            className="Tbutton6"
                            onClick={() => removeView(index)}
                          >
                            <span>-</span>
                          </button>
                        </div>
                      ))}

                      {fetchUser.isEndTimeReached ? (
                        <div className="Tbox">
                          <p className="Ttext">End Time is Reached</p>
                        </div>
                      ) : (
                        <div className="Tbox1">
                          {showAddOneButton && (
                            <button
                              className="Tbutton"
                              onClick={() => addView()}
                            >
                              {/* <div className="Tbutton1"> */}
                              <span className="Tbutton2">Add one</span>
                              <span className="Tbutton3">+</span>
                              {/* </div> */}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="SpriceBreakup">
                  <span className="StitleContainer">{"Price Breakup"}</span>
                  <Table tableDetail={fetchUser} />
                </div>
              </div>
            </div>
          )}

          {fetchUser.is_disabled == true && (
            <div className="SpriceBreakup">
              <span className="StitleContainer">{"Price Breakup"}</span>{" "}
              <Table tableDetail={fetchUser} />
            </div>
          )}

          {fetchUser.is_disabled !== true && (
            <div className="PickBottomContainer">
              <div className="pick-row-container">
                <div className="pick-column-container">
                  <p className="Pick-text-lotto">{`${"Selected Tickets"} : ${count}`}</p>
                  <p className="pick-text-amount">{`₹${fetchUser.ticket_cost} * ${count}`}</p>
                </div>
                <div className="pick-column-container">
                  <p className="Pick-text-lotto">{"Total Amount"}</p>
                  <p className="pick-text-amount">
                    {count * fetchUser.ticket_cost}
                  </p>
                </div>
              </div>
              <div className="Purchase-bottom-container">
                <button
                  className={`Purchase-bottom-button ${
                    views.length === 0 ? "disabled" : "enabled"
                  }`}
                  onClick={HandlePressPurchase}
                  disabled={views.length === 0}
                >
                  {"Proceed Purchase"}
                </button>
              </div>
            </div>
          )}

          {console.log("state", fetchUser)}

          {fetchUser.is_disabled && (
            <div className="PickBottomContainer">
              <span style={{ textAlign: "center", alignSelf: "center" }}>
                Coming soon...
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PickTickets;
