import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import ImageUpload from "../../components/ImageUpload";
import Pan from "./../../assets/images/UploadPan.png";
import { API } from "../../services/API";

const PanScreen = () => {
  const location = useLocation();
  const [panImage, setPanImage] = useState(null);
  const history = useNavigate();

  const handleImageUpload = (file) => {
    setPanImage(file);
  };

  const uploadKycToServer = async (aadharImage, panImage) => {
    const userTokenString = localStorage.getItem("storeData");
    const userToken = JSON.parse(userTokenString);

    let formData = new FormData();
    formData.append("aadhar", aadharImage);
    formData.append("pan", panImage);
    formData.append("mobile_no", userToken.data.mobile_no);

    try {
      const response = await fetch(API.kycUpdate, {
        method: "POST",
        body: formData,
      });

      const jsonResponse = await response.json();

      if (jsonResponse.status) {
        const addData = localStorage.getItem("storeData");

        if (addData) {
          const storedData = JSON.parse(addData);
          storedData.data.kycverificationStatus = "U";
          localStorage.setItem("storeData", JSON.stringify(storedData));
          history("/MainLayout/Profile");
        }
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const handleSubmit = () => {
    console.log('ss',location.state)
    const { aadharImage } = location.state;
    if (aadharImage && panImage) {
      uploadKycToServer(aadharImage, panImage);
      history("/MainLayout/Profile");
    } else {
      alert("Please upload both Aadhar and Pan images.");
    }
  };

  return (
    <div className="AScontainer">
      <div className="DepositHead">
        <button
          className="header"
          style={{ paddingLeft: "10px" }}
          onClick={() => history(-1)}
        >
          <IoArrowBackSharp />
        </button>
        <h1 className="text1">Pan Details</h1>
      </div>

      <ImageUpload
        width={400}
        height={250}
        borderRadius={5}
        iconSize={30}
        img={Pan}
        onImageUpload={handleImageUpload}
      />

      <div className="AStitleContainer" onClick={handleSubmit}>
        <span className="AStitleText">Submit Pan Details</span>
      </div>
    </div>
  );
};

export default PanScreen;
