// import React from 'react'
// import BottomNav from './BottomNav'

// function Settings() {
//   return (
//    <BottomNav/>
//   )
// }

// export default Settings

import React from 'react'

export default function Settings() {
  return (
    <div>Settings</div>
  )
}
