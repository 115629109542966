import React from 'react'
import { IoArrowBackSharp } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineArrowForwardIos } from "react-icons/md";
import './LevelWise.css'

const LevelWise = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = location;
    const { id, Referrals, Deposit } = state;

    const GameType = [
        { id: 1, name: "sai", Deposit: 17000, referred: '20/10/2023' },
        { id: 2, name: "sai", Deposit: 17000, referred: '20/10/2023' },
        { id: 3, name: "sai", Deposit: 17000, referred: '20/10/2023' },
        { id: 4, name: "sai", Deposit: 17000, referred: '20/10/2023' },
        { id: 5, name: "sai", Deposit: 17000, referred: '20/10/2023' },
        { id: 6, name: "sai", Deposit: 17000, referred: '20/10/2023' },

    ];

    const renderItem = (item) => {
        return (
            <div className="LWRowStyle">
                <label className="LWtext2">{item.name}</label>
                <label className="LWtext2">{item.Deposit}</label>
                <label className="LWtext2">{item.referred}</label>

            </div>
        );
    };

    return (
        <div className={"RDContainer"}>

            <div className="DepositHead">
                <button className="header" onClick={() => navigate(-1)}>
                    <IoArrowBackSharp />
                </button>
                <h1 className="text1">Level wise</h1>
            </div>

            <div className="LWbg">
                <div className='RDAlign'>
                    <label className="RDtext">Level {id}</label>
                    <label className="RDtext">(Referrals:{Referrals}  Deposit:{Deposit})</label>
                </div>

            </div>
            <div className="LWRowStyle1">
                <label className="LWtext1">Username </label>
                <label className="LWtext1">Deposits</label>
                <label className="LWtext1">Referred on</label>
            </div>

            {/* <div className='RDtable'> */}
            {GameType.map((item) => renderItem(item))}
            {/* </div> */}
        </div>)
}

export default LevelWise