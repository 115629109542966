import React, { useState,useEffect } from "react";
import OTPInput from "otp-input-react";
import "./Referral.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../services/API";
import { ToastContainer, toast } from "react-toastify";

const Referral = () => {
  const [password, setPassword] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleInputChange = (ev) => {
    const value = ev.target.value;
    if (/^\d*$/.test(value)) {
      setPassword(value);
      setIsCheckboxChecked(value.length === 10);
    }
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const HandlePress = async () => {
    const userToken = localStorage.getItem("storeData");

    // if (userToken) {
    const Token = JSON.parse(userToken);
    const tokenOnly = Token.data.token;
    // console.log('tokenOnly', tokenOnly);
    // }

    // console.log('phone', phoneNumber);

    // setIsLoading(true);

    const data = JSON.stringify({
      token: tokenOnly,
      refUserNumber: parseInt(password),
    });

    console.log("data", data);

    try {
      const response = await axios.post(API.AddRefferalUser, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;
      // console.log('ResponseData in Referral Screen', ResponseData);

      if (ResponseData.status === "success") {
        toast.success(ResponseData.message);

        // await AsyncStorage.setItem('storeData', JSON.stringify(responseData));
        navigate("/MainLayout");
      }

      // const StoringData = JSON.stringify(userToken);
      // await AsyncStorage.setItem('StoringData', StoringData);
      // props.navigation.navigate('LandingPageDrawer');
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data.message);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mainContainer1">
      {/* <div className={"loginContainer"}>
        <div className="loginTitle">Referral </div>
        <div className={"LoginDescription"}>
          Enter the referred person's phone number
        </div>
      </div> */}
      <div className={"LHeaderContainer"}>
        <div className={"LTitleContainer"}>
          <div className="Ltitle">Referral</div>
          <div className={"LDescription"}>
          Enter the referred person's phone number
            {/* {activeTab === "phone" ? "phone number" : "email"} */}
          </div>
          <div className={"LDescription"}>
            If you forget your password, please contact customer service
          </div>
        </div>
      </div>

      <div className="inputWithIcon">
        <input
          type="tel"
          placeholder="Enter Mobile number"
          value={password}
          maxLength={10}
          onChange={handleInputChange}
          // onChange={(ev) => setPassword(ev.target.value)}
          className={"inputBox3"}
        />

        <button
          disabled={!isCheckboxChecked}
          className={"loginButton"}
          onClick={HandlePress}
        >
          Submit Referral code
        </button>

        <button
          className={"loginButton"}
          onClick={() => navigate("/MainLayout")}
        >
          CONTINUE
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Referral;
