import React from 'react'
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import './ReferralData.css'
import Referrals from '../Referrals/Referrals';
import Deposit from '../AllTab/Wallet/Deposit/Deposit';
import { MdOutlineArrowForwardIos } from "react-icons/md";


const ReferralData = () => {
    const navigate = useNavigate();

    const GameType = [
        { id: 1, Referrals: 50, Deposit: 17000 },
        { id: 2, Referrals: 50, Deposit: 17000 },
        { id: 3, Referrals: 50, Deposit: 17000 },
        { id: 4, Referrals: 50, Deposit: 17000 },
        { id: 5, Referrals: 50, Deposit: 17000 },
        { id: 6, Referrals: 50, Deposit: 17000 },

    ];

    const renderItem = (item) => {
        return (
            <div className="RDRowStyle" onClick={() => navigate('/LevelWise', { state: item })}>
                <label className="RDtext1">Level {item.id}</label>
                <label className="RDtext2">(Referrals:{item.Referrals}  Deposit:{item.Deposit})</label>
                <div className="RDDiv2">
                    <MdOutlineArrowForwardIos />
                </div>
            </div>
        );
    };

    return (
        <div className={"RDContainer"}>

            <div className="DepositHead">
                <button className="header" onClick={() => navigate(-1)}>
                    <IoArrowBackSharp />
                </button>
                <h1 className="text1">Referrals data</h1>
            </div>

            <label style={{marginTop:20}}>No Referral data available
            </label>

            {/* <div className="RDbg">
                <div className='RDAlign'>
                    <label className="RDtext">Total Referrals :</label>
                    <label className="RDtext">0</label>
                </div>
                <div className='RDAlign'>
                    <span className="RDtext">Total Deposits :</span>
                    <label className="RDtext">0</label>
                </div>
                <div className='RDAlign'>
                    <label className="RDtext">Commission Earned :</label>
                    <label className="RDtext">0</label>
                </div>

            </div> */}

            {/* <div className='RDtable'> */}
            {/* {GameType.map((item) => renderItem(item))} */}
            {/* </div> */}
        </div>

    )
}

export default ReferralData