import React from "react";
import splash from './../assets/images/SplashScreen.png'
import './SplashScreen.css'

const SplashScreen = () => {
  return (
    <div className="splashScreen">
      <img src={splash} alt="Splash Image" className="SplashImage" />
    </div>
  );
};

export default SplashScreen;
