import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./ParticularDay.css";
import { API } from "../../../../services/API";
import SkeletonLoader from "../../../../components/SkeletonLoader/SkeletonLoader";

const ParticularDay = () => {
  const [fetchUser, setFetchUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const listRef = useRef(null); // Reference for the list container

  useEffect(() => {
    fetchLotteryDetails();
  }, []);

  const fetchLotteryDetails = async (selectedPrice) => {
    const getTokenDetails = localStorage.getItem("storeData");
    const token = JSON.parse(getTokenDetails);

    const data = JSON.stringify({
      token: token.data.token,
    });

    try {
      const response = await axios.post(API.LotteryDetails, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;
      const selectedLottery = ResponseData.data.find(
        (lottery) => lottery.lotteryId === selectedPrice
      );
      setFetchUser(ResponseData.data);
      setLoading(false);
      if (selectedLottery) {
        navigate("/Dayref", { lotteryData: selectedLottery });
      } else {
        console.log("No lottery found with selected price");
      }
      localStorage.setItem("LotteryInfo", JSON.stringify(ResponseData));
    } catch (error) {
      console.error("Error fetching lottery details:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderItem = (item) => {
    const convertTo12HourFormat = (time) => {
      const splitTime = time.split(":");
      let hours = parseInt(splitTime[0]);
      const minutes = splitTime[1];
      const meridiem = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minutes} ${meridiem}`;
    };

    const dayFormatOnly = (time) => {
      return time.split(" ")[0];
    };

    const dayOnly = dayFormatOnly(item.drawTime);
    const drawTime = convertTo12HourFormat(item.drawTime.split(" ")[1]);

    const numberFormat = (value) =>
      new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(value);

    const onlyCurrencyof = numberFormat(item.prizeMoney);

    const onlyCurrency = (currency) => {
      return currency.split(".")[0];
    };

    return (
      <div className="Pcover" key={item.lotteryId}>
        <button
          className={`Plottery-button ${item.is_disabled ? "disabled" : ""}`}
          onClick={() =>
            navigate("/PickTickets", { state: { lotteryData: item } })
          }
        >
          <div className="Plottery-details">
            <div className="Pcolumn1">
              <div className="PC1">
                <span className="Pwin">{t("WIN")}</span>
                <span className="Pcurrency">
                  {onlyCurrency(onlyCurrencyof)}
                </span>
              </div>
              <>
                <span className="Plottery-id">{t(item.lotteryId)}</span>
              </>
            </div>
            <div className="Pdraw-details">
              <div className="Pcolumn">
                <span className="Pdraws">{t("Draw's AT")}</span>
                <span className="datetime">
                  {dayOnly} {drawTime}
                </span>
              </div>
              <div className="Pcolumn">
                <span className="Pticketprize">TICKET PRIZE</span>
                <span className="Pticket_cost">₹{t(item.ticket_cost)}</span>
              </div>
            </div>
          </div>
        </button>
      </div>
    );
  };

  // Mouse and touch drag scrolling logic
  const handleDragStart = (e) => {
    const list = listRef.current;
    list.isDown = true;
    list.startX = e.pageX || e.touches[0].pageX - list.offsetLeft; // For mouse or touch
    list.scrollLeftStart = list.scrollLeft; // Store the initial scroll position
    list.style.cursor = "grabbing"; // Change cursor to grabbing
  };

  const handleDragEnd = () => {
    const list = listRef.current;
    list.isDown = false;
    list.style.cursor = "grab"; // Reset cursor
  };

  const handleDragMove = (e) => {
    const list = listRef.current;
    if (!list.isDown) return;
    e.preventDefault();
    const x = e.pageX || e.touches[0].pageX - list.offsetLeft; // For mouse or touch
    const walk = (x - list.startX) * 1.5; // Adjust scroll speed for smoothness
    list.scrollLeft = list.scrollLeftStart - walk; // Update scroll position
  };

  return (
    <div className="Pcontainer">
      {loading ? (
        <div className="fit">
          <SkeletonLoader height={160} width={330} count={10} />
        </div>
      ) : (
        <div className="fit">
          {fetchUser.length === 0 ? (
            <>
              <h3 className="PtitleContainer">{t("Today Draws")}</h3>
              <div className="Pcover1">
                <p className="PtitleContainer1">
                  {t(
                    "Today's tickets are sold out. Boost your chances with tickets for upcoming draws."
                  )}
                </p>
              </div>
            </>
          ) : (
            <>
              <h3 className="PtitleContainer">
                {t(fetchUser[0].lotteryDayName)}
              </h3>
              <div
                className="Plottery-list"
                ref={listRef}
                onMouseDown={handleDragStart}
                onMouseLeave={handleDragEnd}
                onMouseUp={handleDragEnd}
                onMouseMove={handleDragMove}
                onTouchStart={handleDragStart}
                onTouchEnd={handleDragEnd}
                onTouchMove={handleDragMove}
              >
                {fetchUser.map((item) => renderItem(item))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ParticularDay;
