import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AdharScreen.css";
import { IoArrowBackSharp } from "react-icons/io5";

import ImageUpload from "../../components/ImageUpload";

import Aadhar from "./../../assets/images/UploadAadhar.png";

const AdharScreen = () => {
  const [aadharImage, setAadharImage] = useState(null);
  const history = useNavigate();

  const handleImageUpload = (file) => {
    setAadharImage(file);
    console.log(aadharImage);
  };

  return (
    <div className="AScontainer">
      <div className="DepositHead">
        <button
          className="header"
          style={{ paddingLeft: "10px" }}
          onClick={() => history(-1)}
        >
          <IoArrowBackSharp />
        </button>
        <h1 className="text1">Adhar Details</h1>
      </div>
      <ImageUpload
        width={400}
        height={250}
        borderRadius={5}
        iconSize={30}
        img={Aadhar}
        onImageUpload={handleImageUpload}
      />

      <div
        className="AStitleContainer"
        onClick={() => history("/PanScreen", { state: { aadharImage } })}
      >
        <span className="AStitleText">Fill your Aadhar Details</span>
      </div>
    </div>
  );
};

export default AdharScreen;
