import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import "./Login.css";
import axios from "axios";
import { API } from "../../services/API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { MdPhoneAndroid } from "react-icons/md";

const Login = (props) => {
  const [password, setPassword] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password_set, setPassword_set] = useState(false);
  const [otp_verified, setOtp_verified] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("no-scroll");
    checkAutoLogin();
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const checkAutoLogin = async () => {
    setIsLoading(true);
    try {
      const userToken = localStorage.getItem("storeData");
      if (userToken) {
        const Token = JSON.parse(userToken);
        const tokenOnly = Token.data.token;
        const payload = JSON.stringify({
          token: tokenOnly,
        });
        const response = await axios.post(API.AutoLogin, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = response.data;
        if (responseData.message === "SUCCESS") {
          localStorage.setItem("storeData", JSON.stringify(responseData));
          navigate("/MainLayout");
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error checking auto login:", error.message);
      setIsLoading(false);
    }
  };

  const onButtonClick = async () => {
    console.log("phone", password);
    setIsLoading(true);

    const data = JSON.stringify({
      mobile_no: password,
    });

    try {
      const response = await axios.post(API.RequestOtp, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const ResponseData = response.data;
      setOtp_verified(ResponseData.data.otp_verified);
      setPassword_set(ResponseData.data.password_set);

      if (!ResponseData.data.otp_verified) {
        if (!ResponseData.data.password_set) {
          toast.loading("success");
          navigate("/OTP", { state: { phoneNumber: password } });
        }
      } else if (ResponseData.data.otp_verified) {
        if (ResponseData.data.password_set) {
          navigate("/ConfirmPassword", { state: { phoneNumber: password } });
        } else {
          navigate("/CreatePassword", { state: { phoneNumber: password } });
        }
      }
    } catch (error) {
      if (error.response) {
        toast(error.response.data.message);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error creating request:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (ev) => {
    const value = ev.target.value;
    if (/^\d*$/.test(value)) {
      setPassword(value);
    }
  };

  const handleCheckboxChange = (value) => {
    setIsCheckboxChecked(value);
  };

  const openLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="mainContainer1">
      <div className={"LHeaderContainer"}>
        <div className={"LTitleContainer"}>
          <div className="Ltitle">Log in</div>
          <div className={"LDescription"}>
            Please log in with your phone number
          </div>
          <div className={"LDescription"}>
            If you forget your password, please contact customer service
          </div>
        </div>
      </div>

      <div className="inputWithIcon">
        <div className={"LRow"}>
          <MdPhoneAndroid className={"LIconStyle"} />
          <div className={"LTitleContainer2"}>Phone number</div>
        </div>

        <div className="LAlign">
          <label className="Phcode">+91</label>
          <input
            placeholder="Enter Mobile number"
            type="tel"
            value={password}
            maxLength={10}
            onChange={handleInputChange}
            className="inputBox3"
          />
        </div>
        <div className="inputWithIcon0">
          <Checkbox
            icon={<Icon.FiCheck color="#174A41" size={14} />}
            name="my-input"
            checked={isCheckboxChecked}
            onChange={(value) => handleCheckboxChange(value)}
            borderColor="#000000"
            style={{ cursor: "pointer" }}
            labelStyle={{ marginLeft: 5, userSelect: "none" }}
            label="I certify that I am above 18 years"
          />
        </div>
        <div className="inputWithIcon1">
          <button
            className="loginButton"
            onClick={onButtonClick}
            disabled={!isCheckboxChecked}
          >
            {isLoading ? "Loading..." : "CONTINUE"}
          </button>
          <ToastContainer />
          <div className="row">
            <div className="bottomText">
              By Continuing, I agree to LEGEND7's
            </div>
            <div
              className="darkText"
              style={{ cursor: "pointer", color: "#f18938", textDecoration: "underline" }}
              onClick={() => openLink("https://legend7.in/policy/termsandconditions.html")}
            >
              T&C
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
