
import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useIsFocusVisible,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@mui/material";
import { FaHome } from "react-icons/fa";
import { MdPrivacyTip } from "react-icons/md";
import { PiNoteFill } from "react-icons/pi";
import { MdDelete } from "react-icons/md";
import { FaSignOutAlt } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io"; 
import axios from "axios";
import { API } from "./../../../services/API";
import { toast } from "react-toastify";

const CustomDrawer = ({ isOpen, onClose, routes }) => {
  const isFocused = useIsFocusVisible();
  const [drawerDP, setDrawerDP] = useState(
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
  );
  const [name, setName] = useState();
  const [wallet, setWallet] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    fetchWallet();
    fetchWalletDetails();
  }, [isFocused, drawerDP]);

  const fetchWallet = async () => {
    try {
      const userToken = localStorage.getItem("storeData");
      if (userToken) {
        const userData = JSON.parse(userToken);

        // console.log('main wallet in drawer', userData.data.wallet.main_wallet);
        // setWallet(userData.data.wallet.main_wallet);
        setName(userData.data.name);
        // console.log("firsttt", userData.data.profile_pic);
        setDrawerDP(userData.data.profile_pic);
      }
      // return null;
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
    // console.log('wallet', wallet);
  };

  const fetchWalletDetails = async () => {
    const getTokenDetails = localStorage.getItem("storeData");

    const token = JSON.parse(getTokenDetails);
    const data = JSON.stringify({
      token: token.data.token,
    });

    try {
      const response = await axios.post(API.wallet_info, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;
      console.log(
        "ResponseData in Drawer",
        ResponseData.data.wallet.main_wallet
      );

      setWallet(ResponseData.data.wallet.main_wallet);
      // setIsLoading(false);
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data.message);
        toast.error(error.response.data.message);
        // setIsLoading(false);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <Drawer
     anchor="left"
      open={isOpen}
      onClose={onClose}
      valueclassName="custom-drawer"
      className="one"
      sx={{
        "& .MuiDrawer-paper": {
          width: "60%",
          backgroundColor: "#f18938",
          color: "#FFFFFF",
          // marginLeft:13,
          // zIndex:10000000
        },
      }}
    >
      <div className="TopDrawerContainer">
        <button
          className="profile-button"
          onClick={() => navigate("/MainLayout/Profile")}
        >
          <img
            src={drawerDP}
            // alt="Drawer Profile"
            className="profile-image"
          />
        </button>
        <div className="ProfileText">{name}</div>

        <div
          className="BalanceContainer"
          onClick={()=>navigate('/Deposit')}
        >
          <div className="BalanceRow">
          <FaWallet/>
            <span className="BalanceTextContainer">{"My Balance"}</span>
          </div>
          <span className="BalanceTextContainer">₹ {wallet}</span>
        </div>
      </div>
      <List>
        {routes.map((route, index) => (
          <ListItem
            button
            key={index}
            component={Link}
            to={route.path}
            onClick={onClose}
          >
            <ListItemIcon style={{ color: "#FFFFFF" }}>
              {getIcon(route.name)}
            </ListItemIcon>
            <ListItemText primary={route.name} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

const getIcon = (routeName) => {
  switch (routeName) {
    case "Home":
      return <FaHome />;
    case "Privacy Policy":
      return <MdPrivacyTip />;
    case "Terms Conditions":
      return <PiNoteFill />;
      case "Notification":
        return <IoMdNotifications />;
    case "Delete Info":
      return <MdDelete />;
    case "SignOut":
      return <FaSignOutAlt />;
    default:
      return "";
  }
};

export default CustomDrawer;
