import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Referrals.css";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';
import { IoArrowBackSharp } from "react-icons/io5";


const Referrals = (props) => {
    const navigate = useNavigate();
    const [copySuccess, setCopySuccess] = useState(false);
    const [number, setNumber] = useState('');

    const notify = () => toast('Copied to clipboard', {
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
        },
    });

    useEffect(() => {

        const getTokenDetails = localStorage.getItem("storeData");

        if (!getTokenDetails) {
            toast.error("No token found, please log in again.");
            navigate("/login");
            return;
        }
        const token = JSON.parse(getTokenDetails).data.mobile_no;
        setNumber(token);

    },[number])


    return (
        <div className={"promoContainer"}>
            <div className="DepositHead">
                <button className="header" onClick={() => navigate(-1)}>
                    <IoArrowBackSharp />
                </button>
                <h1 className="text1">Referrals</h1>
            </div>
            {/* <div className="promoheader">Referrals</div> */}

            <div className="Rbg">
                <label className="Rtext">0</label>
                <span className="Rspan">Total commission</span>
                <label className="Rtext1">Upgrade the level to increase commission income</label>

            </div>

            <button className="promotionLinkButton">INVITATION LINK</button>
            <div className="promotionButton">
                <div className="promotionDiv1">
                    <img
                        style={{ height: 42, width: 42 }}
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALBSURBVHgB7ZmxbxMxFMa/d6RFDTCFhYoBFhgpYiEsoCLBAhJMXckUVbCwsvUPQGIAwRhWJiSYUTrBgkQlFjq1YiUoAwpCED++uyARnX0X5+Im1zY/6aLIfnf29+z37DsDc+YcbsTXUF9hER2cg8EyjqDGohMIifLpwC9eO2xjR+7jh89tIwWw48f56Gu0XMY0ifAFv/FxlJBcAfqUHl9And45ilkgHBGDTVnnqGSaZKDPcYa1N1AG/qAtD7Dtqopchcm0iXAVZYGzQJ+xTw6cAtDFpZlNGxdxXxYYhw4sAYn3Dc6jbDD7JZkwhT0CHc79shKn8RQVy8jgLPP8JNQY/DVnzSDXd1CUKEnln4eLKg6jRRSnDpF7mbVJztPHFLKNIqjtmMjRiNt7PgiWPGwmeb7l3ArC8oHXSV5uIYrv/N1CURyZMawARS/JY/k2QYmwzwk3AooVuuOCp/UW7T8hAOEERNxy+bNCxUEEhJxC4+T3nwhEwCmkjwDPFCllFDDAbxQCZqIDmIUEp5EEWQ6DPU28HSi+rxnGoMr912X+qyK/3ffpIuuNTF/IN//lXt/woW8xCYJV/tzGqM4P6ElTjw0XuEZgjL0KGxbtUcQ7FEFw61/nfbFEBoiBWISX99LUxuy8kxBBHHd+FeMzcedjAmUhuQ6MtU3mS4/UEYBQabQ6mM/eBPF+TMCFTK4woL8yoPP3+8K3NoTxfkzglVjW2ME1TJF9vxK7BOyivHTTBbYADbNP3yOs+LIFGLRRVvr6Ol1kC1hCC2VFMFqANDjPpISjoNJynRO4s1BfG3AEzAzpQs2Gq8IpIFFq9CFKg2xkndJkrgO8ocVxu4vZjkQXJmpI0zzJMshdyKTJoDF6kf9eYvpsxm3Ler+VZ+R/zBqfmSnu8PvPTd51ikW+H7F82U0+dhltx9km72Bvzpw5//kLlqijMJTcNZkAAAAASUVORK5CYII="
                    />
                    <div className="promotionDiv3">
                        <span className="promotionDivText">Referral Code</span>
                        <span className="promotionDivText">({number})</span>
                    </div>

                </div>
                <div className="promotionDiv2" onClick={notify}>
                    <FaCopy style={{ fontSize: 20, cursor: 'pointer' }} />
                </div>
            </div>
            <Toaster
                position="bottom-center"
                reverseOrder={false}
            />      <div className="promotionButton" onClick={() => navigate('/ReferralData')}>
                <div className="promotionDiv1">
                    <img
                        style={{ height: 42, width: 42 }}
                        src="https://9987up.club/assets/png/team_port-b79e3d75.png"
                    />
                    <div className="promotionDiv3" >

                        <span className="promotionDivText">Referral data</span>
                    </div>
                </div>

                <div className="promotionDiv2">
                    <MdOutlineArrowForwardIos />
                </div>
            </div>
            <div className="promotionButton" onClick={() => navigate('/CommissionDetails')}>
                <div className="promotionDiv1">
                    <img
                        style={{ height: 42, width: 42 }}
                        src="https://9987up.club/assets/png/commission-4abb55b3.png"
                    />
                    <div className="promotionDiv3">

                        <span className="promotionDivText">Commission detail</span>
                    </div>

                </div>
                <div className="promotionDiv2">
                    <MdOutlineArrowForwardIos />
                </div>
            </div>
        </div>
    );
};

export default Referrals;
