import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Profile.css";
import { API } from "../../../services/API";
import DrawerComponent from "../../Drawer/DrawerScreen/DrawerComponent";
import ImageUpload from "./../../../components/ImageUpload";
import { MdEditSquare, MdNearMeDisabled } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [profileDetails, setProfileDetails] = useState(null);
  const [isKYCComplete, setIsKYCComplete] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const nameInputRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchingProfileDetails();
    checkKYCStatusLocally();
  }, []);

  const handleEditName = () => {
    setIsEditingName(!isEditingName);
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  };

  const fetchingProfileDetails = async () => {
    try {
      const userTokenString = localStorage.getItem("storeData");

      if (userTokenString) {
        const userToken = JSON.parse(userTokenString);
        console.log("ss", userToken.data);
        setProfileDetails(userToken.data);
      }
    } catch (error) {
      console.error("Error fetching profile details:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const checkKYCStatusLocally = async () => {
    try {
      const status = localStorage.getItem("KYCStatus");
      if (status) {
        setIsKYCComplete(JSON.parse(status));
      }
    } catch (error) {
      console.error("Error checking KYC status:", error.message);
    }
  };

  const handleCompleteKYC = () => {
    if (!isKYCComplete) {
      navigate("/KycPage");
    }
  };

  const handleImageUpload = (file) => {
    setSelectedImage(file);
    console.log(selectedImage);
  };

  const handleUpdateProfile = async () => {
    const formData = new FormData();
    formData.append("name", profileDetails.name);
    formData.append("mobile_no", profileDetails.mobile_no);

    if (selectedImage) {
      console.log("sa", selectedImage);
      formData.append("profile_pic", selectedImage);
    }

    try {
      const response = await axios.patch(API.update_profile, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const jsonResponse = response.data;

      console.log("json", jsonResponse);
      toast.success(jsonResponse.message);

      if (jsonResponse.success) {
        const addData = localStorage.getItem("storeData");
        if (addData) {
          const storedData = JSON.parse(addData);
          storedData.data.name = jsonResponse.name;
          storedData.data.profile_pic = jsonResponse.profile_pic;
          localStorage.setItem("storeData", JSON.stringify(storedData));
        }
        navigate("/SplashProfileUpdation");
      }
    } catch (error) {
      console.error("Error updating profile:", error.message);
    }
  };

  const BankManager = () => {
    navigate("/BankDetails");
  };

  return (
    <>
      <DrawerComponent selecteRoute="Profile" />
      {isLoading ? (
        <div className="ProfileContainer">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="scroll-container">
          <div className="ProfileContainer">
            <div className="DrawerContainer"></div>

            <ImageUpload
              height={150}
              width={150}
              borderRadius={100}
              initialImage={profileDetails.profile_pic}
              onImageUpload={handleImageUpload}
            />
            <div className="click">Click to Update Image</div>
            {profileDetails && (
              <div className="profile-details">
                <div className="input1">
                  <div className="input-row">
                    <span className="ProfileText1">Name:</span>
                    {isEditingName ? (
                      <input
                        ref={nameInputRef}
                        autoFocus
                        placeholder="Name"
                        className="TextInputContainer1"
                        value={profileDetails.name}
                        onChange={(e) =>
                          setProfileDetails({
                            ...profileDetails,
                            name: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <span className="TextInputContainer1">
                        {profileDetails.name}
                      </span>
                    )}
                    <button onClick={handleEditName} className="edit-button">
                      {isEditingName ? "✔️" : "✏️"}
                    </button>
                  </div>
                </div>

                <div className="input2">
                  <div className="input-row">
                    <span className="ProfileText1">Ph No:</span>
                    <input
                      readOnly
                      placeholder="Phone Number"
                      className="TextInputContainer"
                      value={profileDetails.mobile_no}
                    />
                    <MdNearMeDisabled className="edit-button" />
                  </div>
                </div>

                {profileDetails.kycverificationStatus && (
                  <div
                    className={`kyc-status kyc-${profileDetails.kycverificationStatus.toLowerCase()}`}
                  >
                    KYC Status: {profileDetails.kycverificationStatus}
                  </div>
                )}

                {["R", "P", "U"].includes(
                  profileDetails.kycverificationStatus
                ) && (
                  <button
                    disabled={
                      profileDetails.kycverificationStatus === "U" ||
                      profileDetails.kycverificationStatus === "S"
                    }
                    className="complete-kyc-button"
                    onClick={handleCompleteKYC}
                  >
                    {profileDetails.kycverificationStatus === "R" ||
                    profileDetails.kycverificationStatus === "P"
                      ? "Complete your KYC Details"
                      : profileDetails.kycverificationStatus === "U"
                      ? "KYC: UNDER PROCESS"
                      : "KYC: VERIFIED"}
                  </button>
                )}

                <button
                  className="update-profile-button"
                  onClick={handleUpdateProfile}
                >
                  Update Profile
                </button>
              </div>
            )}
            <button className="bank-details-button" onClick={BankManager}>
              Bank Details
            </button>

            <button className="bank-details-button" onClick={()=>navigate('/Referrals')}>
              Referrals
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
