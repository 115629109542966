import React, { useState } from 'react';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTicketAlt, faWallet, faTrophy, faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import './MainLayout.css';
import Drawer from './../../Drawer/Drawer';

const MainLayout = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState("Home");

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleRouteChange = (routeName) => {
    setSelectedRoute(routeName);
  };

  const showHeader = location.state?.fromConfirmPassword;
  const isHomeScreen = location.pathname === '/MainLayout/Home';
  return (

    <div className="main-layout">
      {showHeader && (
        <div className="header">
          <button onClick={toggleDrawer}>
            <FontAwesomeIcon icon={faBars} />
          </button>
          <h1>{selectedRoute}</h1>
        </div>
      )}
      <div className="content" style={{ overflowY: 'inherit' }}>
        <Outlet context={{ toggleDrawer, handleRouteChange }} />
      </div>
      {/* {isHomeScreen && (
        <button className='DesktopButton'>
          Add to desktop
        </button>
      )} */}
      <nav className="nav">
        <NavLink to="/MainLayout/Home" className="nav-link" activeClassName="active">
          <FontAwesomeIcon icon={faHome} />
          <span>Home</span>
        </NavLink>
        <NavLink to="/MainLayout/Ticket" className="nav-link" activeClassName="active">
          <FontAwesomeIcon icon={faTicketAlt} />
          <span>Ticket</span>
        </NavLink>
        <NavLink to="/MainLayout/Wallet" className="nav-link" activeClassName="active">
          <FontAwesomeIcon icon={faWallet} />
          <span>Wallet</span>
        </NavLink>
        <NavLink to="/MainLayout/Result" className="nav-link" activeClassName="active">
          <FontAwesomeIcon icon={faTrophy} />
          <span>Result</span>
        </NavLink>
        <NavLink to="/MainLayout/Profile" className="nav-link" activeClassName="active">
          <FontAwesomeIcon icon={faUser} />
          <span>Profile</span>
        </NavLink>
      </nav>
      {/* <Drawer open={drawerOpen} onClose={toggleDrawer} selectedRoute={selectedRoute} /> */}
    </div>
  );
};

export default MainLayout;
