
const baseURL = 'https://api.legend7.in/'


export const API = {
  RequestOtp: baseURL + "api/v1/auth/request-otp",
  VerifyOtp: baseURL + "api/v1/auth/verify-otp",
  LotteryDetails: baseURL + "api/v1/lottery/todayLotteries",
  dayRefLotteries: baseURL + "api/v1/lottery/dayRefLotteries",
  singleLottery: baseURL + "api/v1/lottery/singleLottery",
  miniLotteries: baseURL + "api/v1/lottery/miniLotteries",
  UpcomingLotteries: baseURL + "api/v1/lottery/upcommingLotteries",
  Logout: baseURL + 'api/v1/auth/logout',
  AddRefferalUser: baseURL + 'api/v1/user/AddRefferalUser',
  AutoLogin: baseURL + 'api/v1/auth/auto-login',
  picktickets: baseURL + "api/v1/lottery/picktickets",
  completeOrder: baseURL + "api/v1/lottery/completeOrder",
  update_photo: baseURL + 'api/v1/user/update-photo',
  orderHistory: baseURL + 'api/v1/user/orderhistory',
  transaction_history: baseURL + 'api/v1/user/transaction-history',
  wallet_info: baseURL + "api/v1/user/wallet-info",
  weeklyLotteries: baseURL + 'api/v1/lottery/weeklyLotteries',
  monthlyLotteries: baseURL + 'api/v1/lottery/monthlyLotteries',
  kycUpdate: baseURL + 'api/v1/user/kycUpdate',
  update_profile: baseURL + 'api/v1/user/update-profile',
  userDataDeleteRequest: baseURL + 'api/v1/user/userDataDeleteRequest',
  userWithdrawRequest: baseURL + 'api/v1/user/userWithdrawRequest',
  setPassword: baseURL + "api/v1/auth/setPassword",
  loginUser: baseURL + "api/v1/auth/loginUser",
  previousLotteriesDetails: baseURL + "api/v1/lottery/previousLotteriesDetails",
  overallLotteriesData: baseURL + "api/v1/lottery/overallLotteriesData",
  forgotPasswordrequestOTP: baseURL + "api/v1/auth/forgotPasswordrequestOTP",
  confirmOrder: baseURL + "api/v1/lottery/confirmOrder",
  readuser: baseURL + "api/v1/user/readuser",
  readSingleuser: baseURL + "api/v1/user/readSingleuser",
  depositURLRequest: baseURL + "api/v1/user/depositURLRequest",
  depositConform: baseURL + "api/v1/user/depositConform",
  check_transaction_status: baseURL + "api/v1/user/check-transaction-status",
  bank_Details: baseURL + 'api/v1/user/addBankAccount',
  Already_Inbank_Details: baseURL + 'api/v1/user/getBankAccount',
  Bonus: baseURL + "api/v1/user/readSingleuser",
  Notification: baseURL + "api/v1/user/my-notifcations",
  NotifyAvail: baseURL + "api/v1/user/notification-available",
  ReadNotify: baseURL + "api/v1/user/read-notification"

};

// const apiUrl = 'https://api.field7.biz/api/v1/auth/';

// export const autoLogin = async (payload) => {

//     try {
//         const response = await fetch(apiUrl + "auto-login", {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: payload,
//         });

//         const jsonData = await response.json();
//         return jsonData;
//     } catch (error) {
//         throw new Error('autoLogin failed. Please try again.');
//     }
// };