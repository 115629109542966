// Drawer.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Drawer.css';

const Drawer = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  return (
    <div className={`drawer ${open ? 'open' : ''}`}>
      <div className="drawer-content">
        <button onClick={onClose}>Close</button>
        <nav>
          <ul>
            <li><button onClick={() => handleNavigation('/MainLayout/Home')}>Home Drawer</button></li>
            <li><button onClick={() => handleNavigation('/Layout/Privacy')}>Privacy Policy</button></li>
            <li><button onClick={() => handleNavigation('/Layout/TC')}>Terms & Conditions</button></li>
            <li><button onClick={() => handleNavigation('/Layout/DeleteInfo')}>Delete Information</button></li>
            <li><button onClick={() => handleNavigation('/Layout/SignOut')}>Sign Out</button></li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Drawer;
