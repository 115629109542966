import React, { useEffect } from "react";
import { API } from "../../../services/API";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function SignOut() {
  const navigate = useNavigate();
  useEffect(() => {
    RemoveData();
  }, []);

  const RemoveData = async () => {
    const userTokenString = localStorage.getItem("storeData");

    // if (userTokenString) {
    const userToken = JSON.parse(userTokenString);

    // console.log('Profile data', userToken.data);
    // setprofileDetails(userToken.data);

    // console.log('Logout Token data@@@', userToken.data.token);

    const data = {
      token: userToken.data.token,
    };

    // console.log('Logout Token after', data);

    try {
      const response = await axios.post(API.Logout, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const ResponseData = response.data;

      if (ResponseData.status === 1) {
        toast.success(ResponseData.message);
        localStorage.clear();
        navigate("/");
      }
      // console.log(ResponseData);

      // console.log('success')
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data.message);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    }
  };
  return <div>SignOut</div>;
}

export default SignOut;
