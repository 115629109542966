import React, { useState, useEffect, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API } from '../../../../services/API';
import './BankDetails.css'
import { useNavigate } from 'react-router-dom';
import { IoArrowBackSharp } from "react-icons/io5";


const BankDetails = (props) => {
    const [dataIn, setDataIn] = useState('');
    const [formData, setFormData] = useState({ token: '', accountNumber: '', ifscCode: '', accountHolderName: '' });
    const navigate = useNavigate();

    useEffect(() => {
        fetchingTokenDetails();
        handlePress();
    }, []);

    const handleFormData = (value, name) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const fetchingTokenDetails = async () => {
        try {
            const getTokenDetails = localStorage.getItem('storeData');
            const token = JSON.parse(getTokenDetails);
            const TokenOnly = token.data.token;
            setFormData((prev) => ({ ...prev, token: TokenOnly }));
        } catch (error) {
            console.log('Error fetching token details:', error);
        }
    };

    const handlePress = async () => {
        try {
            const getTokenDetails = localStorage.getItem('storeData');
            const token = JSON.parse(getTokenDetails);
            const TokenOnly = token.data.token;
            const response = await axios.post(API.Already_Inbank_Details, { token: TokenOnly }, {
                headers: { 'Content-Type': 'application/json' }
            });
            const data = response.data.data.account[0] !== undefined ? response.data.data.account[0] : [];
            setDataIn(data);
        } catch (error) {
            if (error.response) {
                console.log('Server responded with status code:', error.response.status);
                console.log('Response data:', error.response.data.message);
                toast.error(error.response.data.message);
            } else if (error.request) {
                console.log('No response received:', error.request);
            } else {
                console.log('Error creating request:', error.message);
            }
        }
    };

    const addBankDetails = async () => {
        try {
            const response = await axios.post(API.bank_Details, formData, {
                headers: { 'Content-Type': 'application/json' }
            });
            toast.success('Your Bank Details were updated');
           navigate('/ChangeBankDetails');
        } catch (error) {
            if (error.response) {
                console.log('Server responded with status code:', error.response.status);
                console.log('Response data:', error.response.data.message);
                toast.error(error.response.data.message);
            } else if (error.request) {
                console.log('No response received:', error.request);
            } else {
                console.log('Error creating request:', error.message);
            }
        }
    };

    return (
        <div className="BankDetailsContainer">
            <div className="DepositHead">
                <button className="header" onClick={() => navigate(-1)}>
                <IoArrowBackSharp />
                </button>
                <h1 className="text1">Bank Details</h1>
            </div>
            {dataIn.length === 0 ? (
                <div className="BankDetailsBottom">
                    <div className="BankDetailsInput1">
                        <div className="BankDetailsInputWrapper">
                            <input
                                placeholder="Account Holder Name"
                                value={formData.accountHolderName}
                                name='accountHolderName'
                                onChange={(e) => handleFormData(e.target.value, 'accountHolderName')}
                                className="BankDetailsTextInputContainer"
                            />
                        </div>
                    </div>
                    <div className="BankDetailsInput1">
                        <div className="BankDetailsInputWrapper">
                            <input
                                placeholder="Account Number"
                                value={formData.accountNumber}
                                name='accountNumber'
                                onChange={(e) => handleFormData(e.target.value, 'accountNumber')}
                                className="BankDetailsTextInputContainer"
                            />
                        </div>
                    </div>
                    <div className="BankDetailsInput1">
                        <div className="BankDetailsInputWrapper">
                            <input
                                placeholder="IFSC code"
                                value={formData.ifscCode}
                                name='ifscCode'
                                onChange={(e) => handleFormData(e.target.value, 'ifscCode')}
                                className="BankDetailsTextInputContainer"
                            />
                        </div>
                    </div>
                    <button onClick={addBankDetails} className="BankDetailsButtonText2">Submit Bank Details</button>
                </div>
            ) : (
                <div className="BankDetailsBottom">
                    <div className="BankDetailsInput1">
                        <div className="BankDetailsInputWrapper">
                            <input
                                placeholder="Account Holder Name"
                                value={dataIn.account_name}
                                name='accountHolderName'
                                onChange={(e) => handleFormData(e.target.value, 'accountHolderName')}
                                className="BankDetailsTextInputContainer"
                            />
                        </div>
                    </div>
                    <div className="BankDetailsInput1">
                        <div className="BankDetailsInputWrapper">
                            <input
                                placeholder="Account Number"
                                value={dataIn.account_no}
                                name='accountNumber'
                                onChange={(e) => handleFormData(e.target.value, 'accountNumber')}
                                className="BankDetailsTextInputContainer"
                            />
                        </div>
                    </div>
                    <div className="BankDetailsInput1">
                        <div className="BankDetailsInputWrapper">
                            <input
                                placeholder="IFSC code"
                                value={dataIn.ifsc_code}
                                name='ifscCode'
                                onChange={(e) => handleFormData(e.target.value, 'ifscCode')}
                                className="BankDetailsTextInputContainer"
                            />
                        </div>
                    </div>
                    <button onClick={() => navigate('/ChangeBankDetails')} className="BankDetailsButtonText2">Change Bank Details</button>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default BankDetails;
