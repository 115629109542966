import React, { useState,useEffect } from "react";
import OTPInput from "otp-input-react";
import "./OTP.css";
import { API } from "../../services/API";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const OTP = ({route}) => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
 
  const navigate = useNavigate();
  const location = useLocation();

  const password = location.state?.phoneNumber;

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const isOtpComplete = otp.length === 6;

  const verifyOtp = async () => {

    setIsLoading(true);

    const payload = {
      mobile_no: password,
      otp: otp
    };

    console.log(payload);


    try {
      const response = await axios.post(API.VerifyOtp, payload, {
        headers: {
          'Content-Type': 'application/json',
        },

      });

      const ResponseData = response.data;
      // console.log('####', ResponseData);
      localStorage.setItem('storeData', JSON.stringify(ResponseData));
      // console.log('res[ponseee', ResponseData);

      // navigation.navigate('SetPassword');
     navigate('/CreatePassword', { state: {phoneNumber: password} });


    } catch (error) {
      if (error.response) {
        setIsLoading(false)
        console.log('Server responded with status code:', error.response.status);
        console.log('Response data:', error.response.data.message);
        ToastContainer(error.response.data.message);
      } else if (error.request) {
        console.log('No response received:', error.request);
      } else {
        console.log('Error creating request:', error.message);
      }
    }
  };

  return (
    <div className="mainContainer1">
      {/* <div className={"loginContainer"}>
        <div className="loginTitle">Almost there !</div>
        <div className={"LoginDescription"}>Please enter your OTP  ********{password.slice(8)}</div>
      </div> */}
      <div className={"LHeaderContainer"}>
        <div className={"LTitleContainer"}>
          <div className="Ltitle">Almost there !</div>
          <div className={"LDescription"}>
          Please enter your OTP  ********{password.slice(8)}
            {/* {activeTab === "phone" ? "phone number" : "email"} */}
          </div>
          <div className={"LDescription"}>
            If you forget your password, please contact customer service
          </div>
        </div>
      </div>  
      <div className={"inputWithIcon"}>
        <OTPInput
          value={otp}
          onChange={handleOtpChange}
          autoFocus={true}
          OTPLength={6}
          otpType="number"
          disabled={false}
          inputClassName={"innerbox"}
          className={"classbox"}
        />
      <div className="bootomText">Didn't receive OTP? Resend it</div>
      <button
        className={"loginButton"}
        disabled={!isOtpComplete}
        onClick={verifyOtp}
      >
        CONTINUE
      </button>
      </div>
    </div>
  );
};

export default OTP;

// function App() {
//   const [OTP, setOTP] = useState("");
//   return (
//     <>

//       <ResendOTP onResendClick={() => console.log("Resend clicked")} />
//     </>
//   );
// }
