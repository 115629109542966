import React, { useState, useEffect } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import "./password.css";
import { API } from "../../services/API";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const CreatePassword = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber;

  const onButtonClick = () => {
    navigate("/ConfirmPassword");
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };

  const HandlePress = async () => {
    const data = JSON.stringify({
      mobile_no: phoneNumber,
      password: password,
      confirmPassword: password1,
    });

    // console.log('payload in Setpassword', data);

    try {
      const response = await axios.post(API.setPassword, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;
      // console.log('ResponseData in Referral Screen', ResponseData);

      if (ResponseData.message === "SUCCESS") {
        // ToastAndroid.show(ResponseData.message, ToastAndroid.SHORT);

        localStorage.setItem("storeData", JSON.stringify(ResponseData));
        navigate("/Referral");
      }
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data.message);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={"mainContainer1"}>
      {/* <div className={"loginContainer"}>
        <div className="loginTitle">Create your Password</div>
        <div className={"LoginDescription"}>Please Enter your Passwords</div>
      </div> */}
      <div className={"LHeaderContainer"}>
        <div className={"LTitleContainer"}>
          <div className="Ltitle">Create your Password</div>
          <div className={"LDescription"}>
          Please Enter your Passwords
            {/* {activeTab === "phone" ? "phone number" : "email"} */}
          </div>
          <div className={"LDescription"}>
            If you forget your password, please contact customer service
          </div>
        </div>
      </div>
      <ToastContainer />
      <div className="inputWithIcon">
        <div className={"inputBox1"}>
          <input
            type={passwordVisible ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            className={"inputBox2"}
            // style={{ width: "80%" }}
          />
          <button
          //  style={{ width: "20%" }}
          onClick={togglePasswordVisibility} className="iconButton">
            {passwordVisible ? <FiEyeOff size={20} /> : <FiEye size={20} />}
          </button>
        </div>

        <div className={"inputBox1"}>
          <input
            type={passwordVisible1 ? "text" : "password"}
            placeholder="Confirm Password"
            value={password1}
            onChange={(ev) => setPassword1(ev.target.value)}
            className={"inputBox2"}
          />
          <button onClick={togglePasswordVisibility1} className="iconButton">
            {passwordVisible1 ? <FiEyeOff size={20} /> : <FiEye size={20} />}
          </button>
        </div>

        <button className={"loginButton"} onClick={HandlePress}>
          SET PASSWORD
        </button>
      </div>
    </div>
  );
};

export default CreatePassword;
