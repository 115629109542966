import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
// import { useHistory } from 'react-router-dom';
import "./Transaction.css";
import { API } from "../../../services/API";
import Lottie from "lottie-react";
import ticket from "./../../../assets/images/tc.png";
import { useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";


const MyTransaction = ({ route }) => {
  const [userData, setUserData] = useState([]);
  const [expandedOrders, setExpandedOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  //   const history = useHistory();

  useEffect(() => {
    fetchWalletDetails();
  }, []);

  const fetchWalletDetails = async () => {
    const getTokenDetails = localStorage.getItem("storeData");
    const token = JSON.parse(getTokenDetails);

    const data = JSON.stringify({
      token: token.data.token,
    });

    try {
      const response = await axios.post(API.transaction_history, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const ResponseData = response.data;
      setUserData(ResponseData.Data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Handle error
    }
  };

  const toggleOrderExpansion = (index) => {
    const updatedExpandedOrders = [...expandedOrders];
    updatedExpandedOrders[index] = !updatedExpandedOrders[index];
    setExpandedOrders(updatedExpandedOrders);
  };

  const Item = ({ item, index }) => {
    const dateOnly = item.createdAt ? item.createdAt.split("T")[0] : "";
    const [year, month, day] = dateOnly.split("-");
    const formattedDate = `${day}-${month}-${year}`;

    return (
      <div className="TransactionBoxContainer">
        <div className="TransactionRow">
          <div className="Transaction-row-content">
            <div className="Transaction-lottie-container">
              <img src={ticket} className="lottie2" />
            </div>
            <span className="TransactionTextContainer">
              {t("ORDER ID")} : {item.order_id}
            </span>
          </div>
        </div>
        <div className="TransactionRow1">
          <span className="TransactionTextContainer3"> {item.txn_date}</span>
          <div>
            <span
              className={`transaction-amount ${item.transaction_type === "D" ? "red" : "green"
                }`}
            >
              {item.transaction_type === "D" ? "-" : "+"}
              {item.txn_amount}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="TransactionContainer">
          <div className="loading-indicator"></div>
        </div>
      ) : (
        <>
          {userData.length === 0 ? (
            <>
              <div className="DepositHead">
                <button className="header" onClick={() => navigate(-1)}>
                  <IoArrowBackSharp />
                </button>
                <h1 className="text1">Transaction History</h1>
              </div>
              <div className="TransactionContainer">
                <span>No Transaction History</span>
              </div>
            </>
          ) : (
            <>
              <div className="DepositHead">
                <button className="header" onClick={() => navigate(-1)}>
                  <IoArrowBackSharp />
                </button>
                <h1 className="text1">Transaction History</h1>
              </div>
              <div className="Tlist">
                {userData.map((item, index) => (
                  <Item item={item} index={index} key={item._id} />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MyTransaction;
