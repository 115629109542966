import React, { useState,useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import "./password.css";
import axios from "axios";
import { API } from "../../services/API";
import { ToastContainer, toast } from "react-toastify";

const ConfirmPassword = () => {
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber;

  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const HandlePress = async () => {
    // if (email == '9999999999' && (password == '456789'|| password == '123456')) {
    //     console.log('suess')
    //     navigation.navigate('Web')
    // } else
    // {

    const data = JSON.stringify({
      mobile_no: phoneNumber,
      password: password,
    });

    // console.log("payload in confirmpassword", data);

    try {
      const response = await axios.post(API.loginUser, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;
      // console.log('ResponseData in Referral Screen', ResponseData);

      if (ResponseData.status === "success") {
        localStorage.setItem("storeData", JSON.stringify(ResponseData));

        // navigate('/AllTab');
        // navigate('/MainLayout');
        navigate("/MainLayout", { state: { fromConfirmPassword: true } });
        toast.success(ResponseData.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data.message);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    } finally {
      setIsLoading(false);
      // }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const forgotHandleClick = async () => {
    const data = JSON.stringify({
      mobile_no: phoneNumber,
    });

    // console.log("payload in ForgetPassword", data);

    try {
      const response = await axios.post(API.forgotPasswordrequestOTP, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;
      // console.log("ResponseData in ForgetPassword", ResponseData);

      if (ResponseData.status == 1) {
        toast.success(ResponseData.message);

        // await AsyncStorage.setItem('storeData', JSON.stringify(ResponseData));

        navigate("/OTP", { state: { phoneNumber: phoneNumber } });
      }
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data.message);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={"mainContainer1"}>
      {/* <div className={"loginContainer"}>
        <div className="loginTitle">Submit your Password</div>
        <div className={"LoginDescription"}>
          Please Enter your Confirm Password
        </div>
      </div> */}
      <div className={"LHeaderContainer"}>
        <div className={"LTitleContainer"}>
          <div className="Ltitle">Submit your Password</div>
          <div className={"LDescription"}>
          Please Enter your Confirm Password
            {/* {activeTab === "phone" ? "phone number" : "email"} */}
          </div>
          <div className={"LDescription"}>
            If you forget your password, please contact customer service
          </div>
        </div>
      </div>

      <div className="inputWithIcon">
        <div className="inputBox1">
          <input
            type={passwordVisible ? "text" : "password"}
            placeholder="Confirm Password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            className={"inputBox2"}
          />
          <button onClick={togglePasswordVisibility} className="iconButton">
            {passwordVisible ? <FiEyeOff size={20} /> : <FiEye size={20} />}
          </button>
        </div>
        <ToastContainer />
        <button className={"loginButton"} onClick={HandlePress}>
          SUBMIT PASSWORD
        </button>
        <div style={{ width: "85%" }}>
          <div className="forgetContainer" onClick={forgotHandleClick}>
            Forgot your Password?
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPassword;
