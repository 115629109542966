import React from "react";
import HomeDrawer from "../../Drawer/HomeDrawer/HomeDrawer";
import Layout from "../../Drawer/DrawerScreen/Layout";
import DrawerComponent from "../../Drawer/DrawerScreen/DrawerComponent";
import { useNavigate, useOutletContext } from "react-router-dom";
import UpComingDays from "../../UpComingDays/UpComingDays";
import MegaPrizes from "./MegaPrizes/MegaPrizes";
import ParticularDay from "./ParticularDay/ParticularDay";
import DailyMiniLotto from "./DailyMiniLotto/DailyMiniLotto";
import "./Lottery.css";
import WeeklyPrizes from "./MegaPrizes/WeeklyPrizes";
import { IoArrowBackSharp } from "react-icons/io5";


export default function Lottery() {
  const navigate = useNavigate();
  // const { toggleDrawer } = useOutletContext();

  return (
    <div style={{ backgroundColor: "#ededf5", overflowX:'hidden' }}>
       <div className="DepositHead">
                <button className="header" onClick={() => navigate(-1)}>
                <IoArrowBackSharp />
                </button>
                <h1 className="text1">Lottery</h1>
            </div>
      {/* <DrawerComponent /> */}
      <div className="LotterySection-container1">
        <MegaPrizes />
      </div>
      <div className="LotterySection-container2">
        <ParticularDay /> 
      </div>
      <div className="LotterySection-container">
        <DailyMiniLotto />
      </div>
      <div className="LotterySection-container">
        <WeeklyPrizes />
      </div>
      <div className="LotterySection-container">
        <UpComingDays />
      </div>
    </div>
  );
}
