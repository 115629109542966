import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./DailyMiniLotto.css";
import { API } from "../../../../services/API";
import SkeletonLoader from "../../../../components/SkeletonLoader/SkeletonLoader";

const DailyMiniLotto = ({ refresh }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const listRef = useRef(null); // Reference for the list container

  const [fetchUser, setFetchUser] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLotteryDetails();
  }, [refresh]);

  const fetchLotteryDetails = async (selectedlotteryId) => {
    const getTokenDetails = localStorage.getItem("storeData");
    const token = JSON.parse(getTokenDetails);

    const data = JSON.stringify({
      token: token.data.token,
    });

    try {
      const response = await axios.post(API.miniLotteries, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;

      setFetchUser(ResponseData.data);
      setLoading(false);
      const selectedLottery = ResponseData.data.find(
        (lottery) => lottery.lotteryId === selectedlotteryId
      );
      if (selectedLottery) {
        navigate("/SinglePrice", { state: { lotteryData: selectedLottery } });
      }
      const StoringData = JSON.stringify(ResponseData);
      localStorage.setItem("LotteryInfo", StoringData);
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const convertTo12HourFormat = (time) => {
    const splitTime = time.split(":");
    let hours = parseInt(splitTime[0]);
    const minutes = splitTime[1];
    const meridiem = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${meridiem}`;
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  const onlyCurrency = (currency) => {
    const splitCurrency = currency.split(".")[0];
    return splitCurrency;
  };

  const renderItem = (item) => {
    const drawTime = convertTo12HourFormat(item.drawTime.split(" ")[1]);
    const onlyCurrencyof = numberFormat(item.prizeMoney);

    return (
      <div
        className="Dlottery-card"
        onClick={() =>
          navigate("/PickTickets", { state: { lotteryData: item } })
        }
        disabled={item.is_disabled === true}
        key={item.lotteryId}
      >
        <div className="DwinContainer1">
          <p className="Dwin">{t("WIN")}</p>
          <p className="Drs">{onlyCurrency(onlyCurrencyof)}</p>
          <p className="Dtiming">{t("Draws At")}</p>
          <p className="Dtiming">{drawTime}</p>
          <p className="Dbuy">BUY ₹{item.ticket_cost}</p>
        </div>
      </div>
    );
  };

  // Mouse drag scrolling logic
  const handleMouseDown = (e) => {
    const list = listRef.current;
    list.isDown = true;
    list.startX = e.pageX - list.offsetLeft;
    list.scrollLeftStart = list.scrollLeft; // Store the initial scroll position
    list.style.cursor = "grabbing"; // Change cursor to grabbing
  };

  const handleMouseLeave = () => {
    const list = listRef.current;
    list.isDown = false;
    list.style.cursor = "grab"; // Reset cursor
  };

  const handleMouseUp = () => {
    const list = listRef.current;
    list.isDown = false;
    list.style.cursor = "grab"; // Reset cursor
  };

  const handleMouseMove = (e) => {
    const list = listRef.current;
    if (!list.isDown) return;
    e.preventDefault();
    const x = e.pageX - list.offsetLeft;
    const walk = (x - list.startX) * 1.5; // Adjust scroll speed for smoothness
    list.scrollLeft = list.scrollLeftStart - walk; // Update scroll position
  };

  return (
    <>
      {loading ? (
        <div className='fit'>
          <SkeletonLoader height={193} width={155} count={20} />
        </div>
      ) : (
        <>
          {fetchUser.length === 0 ? (
            <>
              <h3 className="DtitleContainer11">{t("Daily Mini Draws")}</h3>
              <div className="Dno-tickets">
                <p className="DtitleContainer1">
                  {t(
                    "Today's tickets are sold out. Boost your chances with tickets for upcoming draws."
                  )}
                </p>
              </div>
            </>
          ) : (
            <div>
              <p className="DtitleContainer">
                {t("Daily Mini Draws")} -{" "}
                <span className="titleContainer2">
                  {" "}
                  ₹ {fetchUser[0].ticket_cost}
                </span>
              </p>
              <div
                className="Dlottery-list"
                ref={listRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
              >
                {fetchUser.map((item) => renderItem(item))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DailyMiniLotto;
