// App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Screens/Login/Login";
import OTP from "./Screens/OTP/OTP";
import CreatePassword from "./Screens/password/CreatePassword";
import ConfirmPassword from "./Screens/password/ConfirmPassword";
import Referral from "./Screens/Referral/Referral";
import Dayref from "./Screens/Dayref/Dayref";
import Ticket from "./Screens/AllTab/Ticket/Ticket";
import Wallet from "./Screens/AllTab/Wallet/Wallet";
import Result from "./Screens/AllTab/Result/Result";
import Profile from "./Screens/AllTab/Profile/Profile";
import Settings from "./Screens/HomeTab/Settings";
import About from "./Screens/HomeTab/About";
import MainLayout from "./Screens/AllTab/MainLayout/MainLayout";
import MyTransaction from "./Screens/AllTab/Wallet/Transaction";
import Deposit from "./Screens/AllTab/Wallet/Deposit/Deposit";
import HomeDrawer from "./Screens/Drawer/HomeDrawer/HomeDrawer";
import Layout from "./Screens/Drawer/DrawerScreen/Layout";
import Privacy from "./Screens/Drawer/Privacy/Privacy";
import TC from "./Screens/Drawer/T&C/TC";
import DeleteInfo from "./Screens/Drawer/DeleteInfo/DeleteInfo";
import SignOut from "./Screens/Drawer/SignOut/SignOut";
import UpComingDays from "./Screens/UpComingDays/UpComingDays";
import PickTickets from "./Screens/PickTickets/PickTickets";
import SplashProfileUpdation from "./Screens/Splash/SplashProfileUpdation";
import WinnerList from "./Screens/WinnerList/WinnerList";
import BankDetails from "./Screens/AllTab/Profile/BankDetails/BankDetails";
import ChangeBankDetails from "./Screens/AllTab/Profile/BankDetails/ChangeBankDetails";
import AdharScreen from "./Screens/AdharScreen/AdharScreen";
import PanScreen from "./Screens/PanScreen/PanScreen";
import PaymentGateway from "./Screens/PaymentGateway/PaymentGateway";
import Splash from "./Screens/Splash/Splash";
import SplashWithdraw from "./Screens/Splash/SplashWithdraw";
import SplashScreen from "./Screens/SplashScreen";
import KycPage from "./Screens/KycPage/KycPage";
import Lottery from "./Screens/AllTab/Home/Lottery";
import { Home } from "./Screens/Home";
import Referrals from "./Screens/Referrals/Referrals";
import ReferralData from "./Screens/ReferralData/ReferralData";
import LevelWise from "./Screens/LevelWise/LevelWise";
import CommissionDetails from "./Screens/CommissionDetails/CommissionDetails";
import Notification from "./Screens/Notification/Notification";


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      // setLoggedIn(true);
    }, 2000);
  }, []);

  if (loading) {
    return <SplashScreen />;
  }
  return (
    // <div className="main-root">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/OTP" element={<OTP />} />
        <Route path="/CreatePassword" element={<CreatePassword />} />
        <Route path="/ConfirmPassword" element={<ConfirmPassword />} />
        <Route path="/Referral" element={<Referral />} />
        <Route path="/Dayref" element={<Dayref />} />
        <Route path="/Referrals" element={<Referrals />} />
        <Route path="/ReferralData" element={<ReferralData />} />
        <Route path="/LevelWise" element={<LevelWise />} />
        <Route path="/CommissionDetails" element={<CommissionDetails />} />


        <Route path="/MyTransaction" element={<MyTransaction />} />
        <Route path="/Deposit" element={<Deposit />} />
        <Route path="/PickTickets" element={<PickTickets />} />
        <Route path="/UpComingDays" element={<UpComingDays />} />
        <Route path="/SplashProfileUpdation" element={<SplashProfileUpdation />} />
        <Route path="/Splash" element={<Splash />} />
        <Route path="/SplashWithdraw" element={<SplashWithdraw />} />
        <Route path="/WinnerList" element={<WinnerList />} />
        <Route path="/BankDetails" element={<BankDetails />} />
        <Route path="/ChangeBankDetails" element={<ChangeBankDetails />} />
        <Route path="/KycPage" element={<KycPage />} />
        <Route path="/AdharScreen" element={<AdharScreen />} />
        <Route path="/PanScreen" element={<PanScreen />} />
        <Route path="/PaymentGateway" element={<PaymentGateway />} />
        <Route path="/Lottery" element={<Lottery />} />
        <Route path="/Notification" element={<Notification />} />



        <Route path="/Layout" element={<Layout />}>
          <Route index element={<HomeDrawer />} />
          <Route path="Privacy" element={<Privacy />} />
          <Route path="TC" element={<TC />} />
          <Route path="DeleteInfo" element={<DeleteInfo />} />
          <Route path="SignOut" element={<SignOut />} />
        </Route>

        <Route path="/MainLayout" element={<MainLayout />}>
          <Route index element={<Navigate to="/MainLayout/Home" />} />
          <Route path="Home" element={< Home />} />
          <Route path="Ticket" element={<Ticket />} />
          <Route path="Wallet" element={<Wallet />} />
          <Route path="Result" element={<Result />} />
          <Route path="Profile" element={<Profile />} />
          <Route path="Settings" element={<Settings />} />
          <Route path="About" element={<About />} />
        </Route>

        {/* <Route path="/MainLayout" element={<MainLayout />}>
            <Route index element={<Navigate to="HomeTab" />} />
            <Route path="HomeTab" element={<HomeTab />} />
            <Route path="Activity" element={<Activity />} />
            <Route path="Promotion" element={<Promotion />} />
            <Route path="Wallet" element={<Wallet />} />
            <Route path="Account" element={<Account />} />
          </Route> */}
      </Routes>
    </BrowserRouter>
    // </div>
  );
}

export default App;
