// Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import DrawerComponent from './DrawerComponent';

const Layout = () => {
  return (
    <div>
      <DrawerComponent />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
