import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../services/API";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import "./WinnerList.css";

const WinnerList = ({ route }) => {
  const [fetchWinnersDetails, setfetchWinnersDetails] = useState("");
  const [loading, setLoading] = useState(true);
  const [expandedItems, setExpandedItems] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const lotteryId = location.state?.lotteryId;

    console.log(lotteryId);

    fetchResultDetails();
  }, []);

  const fetchResultDetails = async () => {
    try {
      const response = await axios.post(
        API.overallLotteriesData,
        {
          token: JSON.parse(localStorage.getItem("storeData")).data.token,
          lotteryId: location.state?.lotteryId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = response.data;
      setfetchWinnersDetails(responseData.data);
      const initialExpandedItems = {};
      responseData.data.forEach((item, index) => {
        initialExpandedItems[index] = false;
      });
      setExpandedItems(initialExpandedItems);
    } catch (error) {
      console.log("Error fetching result details:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleShowAll = (index) => {
    setExpandedItems({
      ...expandedItems,
      [index]: !expandedItems[index],
    });
  };

  const renderItem = ({ item, index }) => {
    const displayedTickets = expandedItems[index]
      ? item.tickets
      : item.tickets.slice(0, 9);

    return (
      <div className="WinnerBoxContainer">
        <div className="WinnerRow2">
          <p className="WinnerTextContainer0">{item.prize}</p>
        </div>
        <div className="WinnerTicketContainer">
          {displayedTickets.map((value, index) => (
            <p key={index} className="WinnerTextContainer">
              {value}
            </p>
          ))}
          {item.tickets.length > 9 && (
            <button
              onClick={() => toggleShowAll(index)}
              className="WinnerShowMoreButton"
            >
              {expandedItems[index] ? "Show less" : "Show more"}
            </button>
          )}
        </div>
      </div>
    );
  };

  // if (loading) {
  //     return <LoadingScreen />;
  // }

  return (
    <div className="infoContainer">
      <div className="DepositHead1">
        <button className="header" onClick={() => navigate(-1)}>
          <IoArrowBackSharp />
        </button>
        <h1 className="text1">Winners List</h1>
      </div>
      {fetchWinnersDetails && (
        <>
          <div className="WinnerRow">
            <div className="WinnerInfoBox">
              <p className="WinnerInfoTitle">{"Date"}</p>
              <p className="WinnerInfoTitle1">
                {fetchWinnersDetails.lottery_date}
              </p>
            </div>
            <div className="WinnerInfoBox">
              <p className="WinnerInfoTitle">{"Drawtime"}</p>
              <p className="WinnerInfoTitle1">
                {fetchWinnersDetails.lottery_draw_time}
              </p>
            </div>
          </div>
          <div className="winnerList">
            {fetchWinnersDetails.lottery_result.map((item, index) => (
              <div key={index} className="winnerItem">
                {renderItem({ item, index })}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default WinnerList;
