import React from 'react'
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { FaCircleDot } from "react-icons/fa6";
import './CommissionDetails.css'

const CommissionDetails = () => {
    const navigate = useNavigate();

    const GameType = [
        { id: 1, Referrals: 50, Deposit: "5%" },
        { id: 2, Referrals: 50, Deposit: "5%" },
        { id: 3, Referrals: 50, Deposit: "5%" },
        { id: 4, Referrals: 50, Deposit: "5%" },
        { id: 5, Referrals: 50, Deposit: "5%" },
        { id: 6, Referrals: 50, Deposit: "5%" },

    ];

    const renderItem = (item) => {
        return (
            <div className="CDRowStyle" >
                <FaCircleDot className='CDicons'/>
                <label className="CDtext1">Level {item.id} commission</label>
                <label className="CDtext2">{item.Deposit}</label>
            </div>
        );
    };
    return (
        <div className={"CDContainer"}>

            <div className="DepositHead">
                <button className="header" onClick={() => navigate(-1)}>
                    <IoArrowBackSharp />
                </button>
                <h1 className="text1">Commission Details</h1>
            </div>

            {GameType.map((item) => renderItem(item))}

        </div>

    )
}

export default CommissionDetails