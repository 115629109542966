import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "./../../../services/API";
// import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import "./Wallet.css";
import { FaForward } from "react-icons/fa";
import useFocusEffect from "../../../components/useFocusEffect";
import DrawerComponent from "../../Drawer/DrawerScreen/DrawerComponent";

const Wallet = (props) => {
  const [fetchUser, setFetchUser] = useState("");
  const [withDrawState, setWithDrawState] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchWalletDetails();
  }, []);

  const fetchWalletDetails = async () => {
    const getTokenDetails = localStorage.getItem("storeData");
    const token = JSON.parse(getTokenDetails);

    const data = JSON.stringify({
      token: token.data.token,
    });

    console.log("first", data);

    try {
      const response = await axios.post(API.wallet_info, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const responseData = response.data;
      console.log("wallet info", responseData.data.wallet);
      setFetchUser(responseData.data);
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        console.error(
          "Server responded with status code:",
          error.response.status
        );
        console.error("Response data:", error.response.data.message);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error creating request:", error.message);
      }
      setIsLoading(false);
    }
  };

  const onChangeText = (text) => {
    setWithDrawState(text.replace(/[^0-9]/g, ""));
  };

  const withDrawDetails = async () => {
    console.log("withdraw amount", withDrawState);
    if (parseInt(withDrawState) === 0) {
      console.log("first");
      alert("Please enter a valid amount greater than 0");
    } else {
      const getTokenDetails = localStorage.getItem("storeData");
      const token = JSON.parse(getTokenDetails);

      const payLoad = JSON.stringify({
        token: token.data.token,
        amount: parseInt(withDrawState),
        payment_type: "IMPS",
      });

      try {
        const withDrawResponse = await axios.post(
          API.userWithdrawRequest,
          payLoad,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const dataInResponseInWithDraw = withDrawResponse.data;
        if (dataInResponseInWithDraw.status === 1) {
          navigate("/SplashWithdraw");
          // alert(dataInResponseInWithDraw.message);
        }
      } catch (error) {
        if (error.response) {
          alert(error.response.data.message);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error creating request:", error.message);
        }
      }
    }
  };

  return (
    <div>
      <DrawerComponent selecteRoute="Wallet" />

      {isLoading ? (
        <div className="loading-container">
          {/* <div className="spinner"> */}
          <div className="double-bounce1"></div>
          {/* <div className="double-bounce2"></div> */}
          {/* </div> */}
        </div>
      ) : (
        < >
          {fetchUser && (
            <div  className="WalletContainer">
              <div className="balance-container">
                <div className="header">
                  <p className="balance-text">{t("Current Balance")}</p>
                  <p className="price-text">₹{fetchUser.wallet.main_wallet}</p>
                  <button
                    className="button"
                    onClick={() => navigate("/Deposit")}
                  > 
                    {t("ADD CASH")}
                  </button>
                </div>

                <div className="pay">
                  <div className="winnings">
                    <div className="winnings-info">
                      <p className="wallet-text-container">{t("Winnings")}</p>
                      {/* <MaterialCommunityIcons name='information-outline' color="#000" size={20} /> */}
                    </div>
                    <p className="amount-container">
                      ₹{fetchUser.wallet.win_wallet}
                    </p>
                  </div>
                  <div className="center">
                    <div className="withdraw">
                      <p className="add-amount-text">
                        {t("Amount to withdraw")}
                      </p>
                      <div className="amount-input">
                        <span style={{ fontSize: 20 }}>₹</span>
                        <input
                          placeholder="0"
                          type="number"
                          value={withDrawState}
                          onChange={(e) => onChangeText(e.target.value)}
                          className="amount"
                        />
                      </div>
                    </div>
                    <button
                      className="button"
                      onClick={withDrawDetails}
                      disabled={parseInt(withDrawState) === 0}
                    >
                      {t("WITHDRAW")}
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="balance-container"> */}
              <button
                className="transactions-button"
                onClick={() => navigate("/MyTransaction")}
              >
                <div>{t("My Transactions")}</div>
                <FaForward className="iconWallet" />
              </button>
              {/* </div> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Wallet;
