import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./ChangeBankDetails.css"; // Assuming you have a CSS file for styles
import { toast } from "react-toastify"; // Using react-toastify for notifications
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../../services/API";
import { useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";

const ChangeBankDetails = (props) => {
  const [dataIn, setDataIn] = useState("");
  const [formData, setFormData] = useState({
    token: "",
    accountNumber: "",
    ifscCode: "",
    accountHolderName: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    HandlePress();
    fetchingTokenDetails();
  }, []);

  const handleFormData = (value, name) => {
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const fetchingTokenDetails = async () => {
    try {
      const getTokenDetails = localStorage.getItem("storeData");
      const token = JSON.parse(getTokenDetails);
      const TokenOnly = token.data.token;
      setFormData((prev) => ({
        ...prev,
        token: TokenOnly,
      }));
    } catch (error) {
      console.log("Error fetching token details:", error);
    }
  };

  const HandlePress = async () => {
    try {
      const getTokenDetails = localStorage.getItem("storeData");
      const token = JSON.parse(getTokenDetails);
      const TokenOnly = token.data.token;
      const response = await axios.post(
        API.Already_Inbank_Details,
        { token: TokenOnly },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data.data.account[0];
      setDataIn(data);
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data.message);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    }
  };

  const AddBankDetails = async () => {
    try {
      const response = await axios.post(API.bank_Details, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Your Bank Details were updated");
      navigate("/MainLayout/profile"); // Assuming you're using react-router for navigation
    } catch (error) {
      if (error.response) {
        console.log(
          "Server responded with status code:",
          error.response.status
        );
        console.log("Response data:", error.response.data.message);
        toast.error(error.response.data.message);
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error creating request:", error.message);
      }
    }
  };

  return (
    <div className="CBcontainer">
      <div className="DepositHead">
        <button className="header" onClick={() => navigate(-1)}>
          <IoArrowBackSharp />
        </button>
        <h1 className="text1">Change Details</h1>
      </div>
      <div className="CBbottom">
        <div className="CBinput1">
          <input
            placeholder="Account Holder Name"
            className="CBTextInputContainer"
            value={formData.accountHolderName}
            onChange={(e) =>
              handleFormData(e.target.value, "accountHolderName")
            }
          />
        </div>
        <div className="CBinput1">
          <input
            placeholder="Account Number"
            className="CBTextInputContainer"
            value={formData.accountNumber}
            onChange={(e) => handleFormData(e.target.value, "accountNumber")}
          />
        </div>
        <div className="CBinput1">
          <input
            placeholder="IFSC code"
            className="CBTextInputContainer"
            value={formData.ifscCode}
            onChange={(e) => handleFormData(e.target.value, "ifscCode")}
          />
        </div>
        <button onClick={AddBankDetails} className="BankDetailsButtonText2">
          Update Bank Details
        </button>
      </div>
    </div>
  );
};

export default ChangeBankDetails;

// ChangeBankDetails.css
