import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import QRCode from "qrcode.react";
import "./PaymentGateway.css";
import { API } from "../../services/API";
import { IoArrowBackSharp } from "react-icons/io5";
import upiImage from "./../../assets/images/upi.jpg";

const PaymentGateway = () => {
  const [message, setMessage] = useState("Waiting for Confirmation");
  const [Id, setId] = useState("");
  const [url, setUrl] = useState("");
  const [seconds, setSeconds] = useState(300);
  const [timerInterval, setTimerInterval] = useState(null);
  const [updateState, setUpdateState] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { responseINCompleteOrder } = location.state;

  useEffect(() => {
    const url = responseINCompleteOrder.data.UPIURL;
    setUrl(url);
    setId(responseINCompleteOrder.data.txn_id);

    if (url) {
      window.open(url, "_blank");
    } else {
      alert("Invalid URL");
    }

    handleStartStop();
    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  const HandlePress = async () => {
    const userToken = localStorage.getItem("storeData");
    const Token = JSON.parse(userToken);
    const tokenOnly = Token.data.token;

    const data = JSON.stringify({
      token: tokenOnly,
      txn_id: responseINCompleteOrder.data.txn_id,
    });

    try {
      const response = await axios.post(API.check_transaction_status, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ResponseData = response.data;

      if (ResponseData.data.status === "PENDING") {
        setMessage("Waiting for Confirmation");
      } else if (ResponseData.data.status === "SUCCESS") {
        setMessage("Confirmation Success");
        setTimeout(() => {
          navigate("/MainLayout/Wallet");
        }, 2000);
      } else {
        setMessage("Waiting for Confirmation");
      }
    } catch (error) {
      console.error("Error fetching transaction status:", error);
    }
  };

  const handleStartStop = () => {
    if (timerInterval === null) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => {
          const updatedSeconds = prevSeconds - 1;
          if (updatedSeconds % 10 === 0) {
            setUpdateState((prevState) => !prevState);
            HandlePress();
          }
          return updatedSeconds;
        });
      }, 1000);
      setTimerInterval(intervalId);
    } else {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
  };

  const formatTime = (totalSeconds) => {
    if (totalSeconds <= 0) {
      clearInterval(timerInterval);
      return "00:00";
    }
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  return (
    <>
      <div className="DepositHead">
        <button className="header" onClick={() => navigate(-1)}>
          <IoArrowBackSharp />
        </button>
        <h1 className="text1">Payment Gateway</h1>
      </div>
      <div className="PaymentGatewayContainer">
        {Id && (
          <div className="PaymentGatewayMessage2">Transaction ID: {Id}</div>
        )}

        {/* <div>Pay via UPI Apps</div> */}

        {/* <button
          onClick={() => window.open(url, "_blank")}
          className="paymentButton"
        >
          UPI
          <img src={upiImage} style={{ height: 60 }} />
        </button> */}

        {/* <div>------ OR ------</div> */}
        {/* <div>Scan and Pay via any upi apps</div> */}
        {/* <div className="QRCodeContainer">
          {url && (
            <QRCode
              value={url}
              size={256}
              bgColor="#FFFFFF"
              fgColor="#000000"
            />
          )}
        </div> */}
        <div className="PaymentGatewayContent">
          <div className="PaymentGatewayRound">
            <div className="PaymentGatewayMessage1">{formatTime(seconds)}</div>
          </div>
          <div className="PaymentGatewayMessage">{message}</div>
        </div>
        <div className="PaymentGatewayRound1">
          <div className="PaymentGatewayMessage3">
            Feel free to close this page. Your wallet will be topped up once we
            receive confirmation.
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentGateway;
